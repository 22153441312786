<!--7th Form-->
<div class="ptb-100">
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center ">
            <h6 class="text-white display-3 mb-4 wow fadeInDown" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInDown;">
                Agreement Form
            </h6>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInDown;">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active text-white">Agreement Form</li>
            </ol>
        </div>
    </div>
    <br /><br /><div style="margin-top:60px"></div>
    <div id="pdf-content" class="mt-5">
        <div class="container-fluid pt-3 content">
            <div class="container">
                <div class="row no-gap">
                    <div class="col-lg-12 ">
                        <h2 class="surr">
                            <b>Agreement</b>
                        </h2>
                        <br />
                        <br />
                    </div>

                    <div class="col-lg-12 col-4 border-top1 border-top2">
                        <p class="mt-3"><span style="color:red;">To:</span></p>
                        <p><span style="color:red;"><b>{{loginDet[0].CompanyName}}</b></span><p>
                        <p><span style="color:red;"><b>{{loginDet[0].CompanyAddress}}</b></span></p>
                        <br />
                        <br />
                        <h5 class="mt-3">Ref No. <strong class="text-danger"> {{notice5}}</strong></h5>

                        <p class="mt-3">
                            <b>Sub:</b> Agreement for sale of vehicle <br /> Madam/Sir
                        </p>
                    </div>
                </div><br />
                <div class="main-content">
                    <p class="para mt-3">
                        We <span style="color:red;">{{Fdata[0].SecondSaleDealerCompanyName}},</span> Add: <span style="color:red;">
                            {{Fdata[0].SecondSaleDealerCompanyAddress}},
                        </span> (second sales dealer) have obtained permission
                        from you to take possession of your finance hypothecation/hire purchase/lease vehicle
                        no <span style="color:red;">{{Fdata[0].VehicleRegNo}}</span>({{wheeltype}}) and sell it within <span style="color:red;">{{Fdata[0].SellWithInDays}}</span> days dated from <span style="color:red;">{{ Fdata[0].DueDate | date:'dd-MM-yyyy'}}</span>.
                    </p>
                    <p class="para mt-3">
                        We agree to facilitate a finance agreement between you and
                        buyer for clearance of amount due to you, handle traffic /RTA
                        documentary/ completion/ clearance matters related to the
                        vehicle.
                    </p>
                    <p class="para mt-3">
                        This agreement is subjected to timely completion of sale
                        purchase. In case the sale is not completed on time, we will pay
                        you reasonable interest with your consent.
                    </p>
                    <p class="para mt-3">
                        You have the authority to terminate this agreement at any
                        time.
                    </p>
                    <p class="para mt-3">
                        By signing below, we agree to terms of this agreement for
                        the benefit of both parties.
                    </p>
                </div><br />
                <div class="row mt-5">
                    <div class="col-lg-6">
                        <div class="">
                            <p class="">
                                Thank you,
                            </p>
                            <p class="">
                                From <span style="color:red;">
    {{Fdata[0].SecondSaleDealerCompanyName}}
</span>
                            </p>
                            <p class="">Date: <span style="color:red;">{{todayDate}}</span></p>
                            <br />
                        </div>
                    </div>
                    <div class="col-lg-3"></div>
                    <div class="col-lg-3 text-left">
                        <div class="text-center">
                            <p>Signature</p><br/>
                        </div>
                    </div>

                </div>

            </div>


        </div>
        <div class="container-fluid">
            <div class="single-line-container content"></div>
            <div class="container last">

            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-lg-8 ">
    </div>
    <div class="col-lg-1 ">
        <button (click)="navigateTOeditform(Fdata[0].RefNo)" class="pdf"><i class="fa fa-edit"></i> Edit  </button>
    </div>
    <div class="col-lg-3">
        <button (click)="downloadPDF()" class="pdf"><i class="fa fa-download"></i> Generate PDF  </button>
    </div>
</div>
