import { Component } from '@angular/core';

@Component({
  selector: 'app-pawndocumentform',
  templateUrl: './pawndocumentform.component.html',
  styleUrl: './pawndocumentform.component.scss'
})
export class PawndocumentformComponent {

}
