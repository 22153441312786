<div class="ptb-100">
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center ">
            <h6 class="text-white display-3 mb-4 wow fadeInDown" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInDown;">
                Pledged Auction Notice
            </h6>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInDown;">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active text-white"> Pledged Auction Notice</li>
            </ol>
        </div>
    </div>
    <div class="container" style="margin-top: 2%;margin-bottom: 15px;">
        <div class="row">
            <div class="col-12">
                <fieldset>

                    <form [formGroup]="RegisterForm">
                        <div class="row p-5">
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BorrowerName">Borrower Name*</label>
                                    <input type="text" formControlName="BorrowerName" placeholder="Enter Your Borrower Name" inputmode="numeric" maxlength="50" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BarrowerAddress">Barrower Address*</label>
                                    <input type="text" formControlName="BarrowerAddress" placeholder="Enter Your Barrower Address" id="VehicleRegNo" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BarrowerMobile">Barrower Mobile*</label>
                                    <input type="text" formControlName="BarrowerMobile" onkeypress="return /[0-9]/i.test(event.key)" placeholder="Enter Your Barrower Mobile" id="VehicleRegNo" required>
                                </div>
                            </div>


                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="AgreementStartDate">Agreement Start Date*</label>
                                    <input type="date" formControlName="AgreementStartDate" placeholder="Enter Your Agreement Start Date" id="BorrowerName" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="LoanAmount">Loan Amount</label>
                                    <input type="text" formControlName="LoanAmount" onkeypress="return /[0-9]/i.test(event.key)" placeholder="Enter Your Loan Amount" id="BarrowerAddress" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="TicketNumber">Ticket Number</label>
                                    <input type="text" formControlName="TicketNumber" placeholder="Enter Your Ticket Number" id="LoanAmount" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="DueAmount">Due Amount</label>
                                    <input type="text" formControlName="DueAmount" onkeypress="return /[0-9]/i.test(event.key)" placeholder="Enter Your Due Amount" id="DueAmount" required>
                                </div>
                            </div>



                            <div class="form-group">
                                <!-- If AgreementNo is null, undefined, or empty, show Submit button -->
                                <button *ngIf="aggrementNo==undefined" class="btn" (click)="getRegistration(RegisterForm.value)">
                                    <b>Preview</b>
                                </button>

                                <!-- If AgreementNo has a value, show Update button -->
                                <button *ngIf="aggrementNo !==undefined" class="btn" (click)="updateRegistration(RegisterForm.value)">
                                    <b>Preview</b>
                                </button>
                            </div>



                            <!--<div class="form-group" (click)="getRegistration(RegisterForm.value)">

                                <button class="btn">
                                    <b>Submit</b>
                                </button>

                            </div>-->
                        </div>
                    </form>
                </fieldset>
            </div>
        </div>
    </div>
    </div>
