<div class="ptb-100">
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center ">
            <h6 class="text-white display-3 mb-4 wow fadeInDown" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInDown;">
                Subscription
            </h6>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInDown;">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active text-white"> Subscription</li>
            </ol>
        </div>
    </div>
    <div class="container mt-5">
        <div class="row">
            <div class="col-lg-4" *ngIf="!LoginDetails || (LoginDetails && LoginDetails[0]?.RoleID === 2)">
                <div class="parent-wrapper">
                    <!--<span class="close-btn glyphicon glyphicon-remove"></span>-->
                    <div class="subscribe-wrapper">
                        <img src="../../../assets/img/law1.jpg" alt="law" />
                        <div class="textt">
                            <h6 class="h66" style="font-size:15px;">{{PackageName}}</h6>

                            <h6 *ngIf="PackageDiscount > 0">{{PackageDiscount}}% Discount</h6>
                            <br />
                            <h6 class="mt-2 lhte">{{PackageDescription}}</h6>

                            <h6 class="mt-2">{{PaymentMode}} Payment</h6>
                            <h6 class="mt-2"><b>{{PackageDuration}}</b></h6>

                            <div class="submit-btn">{{PackagePrice}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4" *ngIf="!LoginDetails || (LoginDetails && LoginDetails[0]?.RoleID === 3)">
                <div class="parent-wrapper">
                    <!--<span class="close-btn glyphicon glyphicon-remove"></span>-->
                    <div class="subscribe-wrapper">
                        <img src="../../../assets/img/pawn.jpg" alt="law2" />
                        <div class="textt">
                            <h6 class="h66" style="font-size:15px;">{{PackageNames}}</h6>
                            <h6 *ngIf="PackageDiscount > 0">{{PackageDiscounts}}% Discount</h6>
                            <br />
                            <h6 class="mt-2 lhte">{{PackageDescriptions}}</h6>

                            <h6 class="mt-2">{{PaymentModes}} Payment</h6>
                            <h6 class="mt-2"><b>{{PackageDurations}}</b></h6>

                            <div class="submit-btn">{{PackagePrices}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4" *ngIf="!LoginDetails || (LoginDetails && LoginDetails[0]?.RoleID === 9)">
                <div class="parent-wrapper">
                    <!--<span class="close-btn glyphicon glyphicon-remove"></span>-->
                    <div class="subscribe-wrapper">
                        <img src="../../../assets/img/secondsale.png" alt="law2" />
                        <div class="textt">
                            <h6 class="h66" style="font-size:15px;">{{PackageNames1}}</h6>
                            <h6 *ngIf="PackageDiscount > 0">{{PackageDiscounts1}}% Discount</h6>
                            <br />
                            <h6 class="mt-2 lhte">{{PackageDescriptions1}}.</h6>

                            <h6 class="mt-2">{{PaymentModes1}} Payment</h6>
                            <h6 class="mt-2"><b>{{PackageDurations1}}</b></h6>

                            <div class="submit-btn">{{PackagePrices1}}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 qrmobileeee">
                <div class="parent-wrapper1">
                    <div class="subscribe-wrapper subscribe-wrapper1">
                        <img src="../../../assets/img/upi.png" class="qrimg" alt="upi" />
                    </div>
                    <div class="textt1">
                        <p class="subc_img1"><b>Note:</b> "Please enter your transaction ID for quick verification"</p>
                        <input class="subc_btn border-end-0" type="text" #transactionInput name="transactionID" placeholder="Your Transaction ID"  minlength="10" maxlength="18"/>
                        <button (click)="subscription(transactionInput.value)" style="background-color:#61a111; color: white; border: 2px solid #61a111">Submit</button>
                    </div>
                </div>
            </div>






        </div>
    </div>
</div>
