import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { GeneralserviceService } from '../../generalservice.service';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-loanform',
 
  templateUrl: './loanform.component.html',
  styleUrl: './loanform.component.scss'
})
export class LoanformComponent {

    HomeUrl: string;
    arr: any[] = [];
    loginDet: any;
    otpSent: boolean = false;
    showLogin: boolean = false;
    showLogin1: boolean = true;
    registerAsDonor: boolean = false;
    RegId: any;
    LoginDetails: any;
    RegisterForm: FormGroup;
    OTPtoMobile: string;
    checkNo: number;
    Mobilenum: string;
    mobiledata: any;
    classdata: any;
    classname: any;
    deptList: any;
    deptList1: any;
    // State: any;
    StateId: any;
    DistrictName: any;
    flag: any;
    rolename: any
    Districtdata: any;
    selectedDistrictName: any;
    Statedata: any;
    State: any = [];
    selectedStateName: any;
    selectedstateid: any;
    district: any = [];
    Citydata: any = [];
    Fdata: any;
    wheeltype: any;
    aggrementNo: any
    isUpdateMode: boolean = false;
    constructor(
        public router: Router,
        private formBuilder: FormBuilder,
        public generalService: GeneralserviceService,
        public http: HttpClient,
        public activeroute: ActivatedRoute,
    ) {
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
        // this.rolename = localStorage.getItem("clickedName");
        this.wheeltype = localStorage.getItem("wheeltype");
        debugger
        this.aggrementNo = localStorage.getItem("AgreementNo");
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });

        this.RegisterForm = this.formBuilder.group({
           
          //  FinancerName: ['', Validators.required],
         //   FinancerAddress: ['', Validators.required],
             Name: ['', Validators.required],
            FatherName: ['', Validators.required],
            Mobile: ['', Validators.required],
            Age: ['', Validators.required],
            Occupation: ['', Validators.required],
            VehicleRegno: ['', Validators.required],
            Vehiclemake: ['', Validators.required],
            Agreementdate: ['', Validators.required],
            TotalAmountwInterest: ['', Validators.required],
            TotalAmount: ['', Validators.required],
            Balance: ['', Validators.required],
            SuretyName: ['', Validators.required],
            Suretymobile: ['', Validators.required],
            Address: ['', Validators.required],
          //  RescheduleAmount: ['', Validators.required],
            RepayingAmount: ['', Validators.required],

        });

        if (!this.loginDet || !this.loginDet[0] || !this.loginDet[0].RegID) {
            this.router.navigate(['/Login']);
            return;
        }

    }

    ngOnInit() {
        this.Getforms();
        this.loadEditData();

    }
    loadEditData(): void {
        const wheeltype = localStorage.getItem('wheeltype');
        if (wheeltype) {
            this.RegisterForm.patchValue({ wheeltype });
        }
    }
    Getforms() {
        debugger;
        const uploadfile = new FormData();
        uploadfile.append('Param', this.aggrementNo);
        var url = "api/FinancierLaw/FA_GetLoanReschedule_FormId";
        this.generalService.Postdata1(url, uploadfile).subscribe(
            data => {
                debugger;
                this.Fdata = data;
                const auctionedDate = this.formatDate(this.Fdata[0].Agreementdate);
                this.RegisterForm.patchValue({
                     
                  //  FinancerName: this.Fdata[0].FinancerName,
                 //   FinancerAddress: this.Fdata[0].FinancerAddress,
                    Name: this.Fdata[0].Name,
                    FatherName: this.Fdata[0].FatherName,
                    Mobile: this.Fdata[0].Mobile,
                    Age: this.Fdata[0].Age,
                    Occupation: this.Fdata[0].Occupation,
                    VehicleRegno: this.Fdata[0].VehicleRegno,
                    Vehiclemake: this.Fdata[0].Vehiclemake,
                   // Agreementdate: this.Fdata[0].Agreementdate,
                    TotalAmountwInterest: this.Fdata[0].TotalAmountwInterest,
                    TotalAmount: this.Fdata[0].TotalAmount,
                    Balance: this.Fdata[0].Balance,
                    SuretyName: this.Fdata[0].SuretyName,
                    Suretymobile: this.Fdata[0].Suretymobile,
                    Address: this.Fdata[0].Address,
                    Agreementdate: auctionedDate,
                    RepayingAmount: this.Fdata[0].RepayingAmount,
                 //   RescheduleAmount: this.Fdata[0].RescheduleAmount,
                    // Add other fields as needed based on Fdata
                });
            },
            err => {
                //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
            }
        );
    }
    formatDate(dateString: string): string {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    }
    onDateChange(event: any) {
        const formattedDate = this.formatDate(event.target.value);
        this.RegisterForm.get('AuctionedDate')?.setValue(formattedDate);
    }
    OnSubmit() {
        throw new Error('Method not implemented.');
    }

    reg() {
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            this.router.navigate(['/Login']);
            return;
        } else {
            this.router.navigate(['/Home']).then(() => window.location.reload());
        }
    }




    getRegistration(value: any) {
        debugger;
        if (this.RegisterForm.invalid) {
            alert('Please fill in all required fields.');
            return; // Stop if validation fails
        }
        this.arr = [];
        const wheeltype = this.RegisterForm.get('wheeltype')?.value;
        var arr = [{
            
            // Name: value.Name,
           // FinancerName: value.FinancerName,
          //  FinancerAddress: value.FinancerAddress,
            Name: value.Name,
            FatherName: value.FatherName,
            Mobile: value.Mobile,
            Age: value.Age,
            Occupation: value.Occupation,
            VehicleRegno: value.VehicleRegno,
            Vehiclemake: value.Vehiclemake,
            Address: value.Address,
            
            Agreementdate: value.Agreementdate,
            TotalAmountwInterest: value.TotalAmountwInterest,
            TotalAmount: value.TotalAmount,
            Balance: value.Balance,
            SuretyName: value.SuretyName,
            Suretymobile: value.Suretymobile,
            RepayingAmount: value.RepayingAmount,
           // RescheduleAmount: value.RescheduleAmount,
            CreatedBy: this.loginDet[0].RegID,
            NoticeID: 16,
            LoanAmount: 0.00,
            DueAmount: 0.00,
            AuctionReceivedMoney: 0.00,
            MonthlyIncome: 0.00

        }];
        localStorage.setItem('wheeltype', wheeltype);

        const uploadfile = new FormData();
        uploadfile.append('Param', JSON.stringify(arr));
        uploadfile.append('Flag', '1');

        const url = 'api/FinancierLaw/FA_LoanRescheduleCRUD';
        this.generalService.Postdata1(url, uploadfile).subscribe((data: any) => {
            debugger

            if (data && data) {

                alert("Your Form submitted successfully");
                localStorage.setItem('AgreementNo', data);
                this.router.navigate(['/Loanreschedule']);


            }
        },

        );
    }
    updateRegistration(value: any) {
        debugger;
        this.arr = [];

        var arr = [{
            Lid: this.Fdata[0].Lid,
           // FinancerName: value.FinancerName,
          //  FinancerAddress: value.FinancerAddress,
            Name: value.Name,
            FatherName: value.FatherName,
            Mobile: value.Mobile,
            Age: value.Age,
            Occupation: value.Occupation,
            VehicleRegno: value.VehicleRegno,
            Vehiclemake: value.Vehiclemake,

            Agreementdate: value.Agreementdate,
            TotalAmountwInterest: value.TotalAmountwInterest,
            TotalAmount: value.TotalAmount,
            Balance: value.Balance,
            Address: value.Address,
            SuretyName: value.SuretyName,
            Suretymobile: value.Suretymobile,
            RepayingAmount: value.RepayingAmount,
          //  RescheduleAmount: value.RescheduleAmount,
            CreatedBy: this.loginDet[0].RegID,
            NoticeID: 16,
            LoanAmount: 0.00,
            DueAmount: 0.00,
            AuctionReceivedMoney: 0.00,
            MonthlyIncome: 0.00
        }];

        const uploadfile = new FormData();
        uploadfile.append('Param', JSON.stringify(arr));
        uploadfile.append('Flag', '2');

        const url = 'api/FinancierLaw/FA_LoanRescheduleCRUD';
        this.generalService.Postdata1(url, uploadfile).subscribe((data: any) => {
            debugger


            if (data && data) {


                alert("Your Form Updated successfully");
                localStorage.setItem('AgreementNo', data);
                this.router.navigate(['/Loanreschedule']);


            }
        },

        );
    }
    convertToUppercase() {
        const control = this.RegisterForm.get('VehicleRegno');
        if (control) {
            control.setValue(control.value.toUpperCase(), { emitEvent: false });
        }
    }

    // Method to fetch states from the server
    

}

