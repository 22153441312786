
<div id="pdf-content" class="pt-100">

    <div class="container mt-4">

        <!-- Next Page Header -->

        <div class="pdf-header">
            <div class="topheader">
                <h1 style="font-size: 40px; text-align:center; font-family: Arial Black; text-decoration: none;">
                    <b>{{loginDet[0].CompanyName | uppercase}}</b>
                </h1>
                <p style="text-align:center; line-height: 1.2rem">
                    <b>{{loginDet[0].CompanyAddress}}</b>, <b>{{loginDet[0].Mobile}}</b>

                </p>
            </div>
        </div>



        <div class="row">
            <div class="col-lg-12">
                <h1 style="text-align:center;">PURCHASE RECEIPT / AGREEMENT</h1>
                <p class="float-end">Date: {{currentDate}}</p><br />
            </div>
        </div>




        <div class="row">

            <div class="col-lg-12 ">
                <div class="seller-details">
                    <h3>Buyer Details:</h3>

                    <div class="row">
                        <div class="col-lg-12 lh_1p8 ">
                            <b>{{buyer.BuyerName}}</b> S/o
                            <b>{{buyer.BuyerFatherName}} </b>,
                            age -<b>{{buyer.BuyerAge}}yrs </b>,Company Name-<b>{{loginDet[0].CompanyName}}</b>
                            Company Address- <b>{{loginDet[0].CompanyAddress}}</b>, 
                            <b>Occupation - {{buyer.BuyerOccupation}}</b>,
                            residing at : Hno {{buyer.BuyerHNo}}, street no.{{buyer.BuyerStreet}}, {{buyer.BuyerColony}}, {{buyer.BuyerMandal}}, {{buyer.BuyerState}}, {{buyer.buyerPincode}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="buyer-details">
                    <h3>Seller Details:</h3>

                    <div class="col-lg-12 lh_1p8">
                        <b>{{seller.SellerName}}</b> S/o
                        <b>{{seller.SellerFatherName}} </b>,
                        age - <b>{{seller.SellerAge}}yrs </b>,
                        <b>Occupation - {{seller.SellerOccupation}} </b>,
                        <!--{{seller.SellerName}} s/o  {{seller.SellerFatherName}}, age - {{seller.SellerAge}}, {{seller.SellerOccupation}},-->
                        residing at : Hno {{seller.SellerHNo}}, street no.{{seller.SellerStreet}}, {{seller.SellerColony}}, {{seller.SellerMandal}}, {{seller.SellerState}}, {{seller.SellerPincode}}
                    </div>


                </div>
            </div>
        </div>
        <div class="vehicle-details">
            <h3>Vehicle Details:</h3>


            <div class="row">
                <div class="col-lg-12">
                    <table class="table">
                        <tbody>
                            <tr *ngIf="Vehicle.VehicleRegistrationNo || Vehicle.VehicleChassisNo || Vehicle.VehicleEngineNo">
                                <td class="border" *ngIf="Vehicle.VehicleRegistrationNo">Registration No</td>
                                <td class="border" *ngIf="Vehicle.VehicleRegistrationNo"><b>{{Vehicle.VehicleRegistrationNo}}</b></td>
                                <td class="border widthh"></td>
                                <td class="border" *ngIf="Vehicle.VehicleChassisNo">Chassis No</td>
                                <td class="border" *ngIf="Vehicle.VehicleChassisNo"><b>{{Vehicle.VehicleChassisNo}}</b></td>
                                <td class="border widthh"></td>
                                <td class="border" *ngIf="Vehicle.VehicleEngineNo">Engine No</td>
                                <td class="border" *ngIf="Vehicle.VehicleEngineNo"><b>{{Vehicle.VehicleEngineNo}}</b></td>
                            </tr>

                            <tr *ngIf="Vehicle.TransportType || Vehicle.VehicleType || Vehicle.VehicleModel">
                                <td class="border" *ngIf="Vehicle.TransportType">TRANSPORT/NON TRANSPORT</td>
                                <td class="border" *ngIf="Vehicle.TransportType"><b>{{Vehicle.TransportType}}</b></td>
                                <td class="border widthh"></td>
                                <td class="border" *ngIf="Vehicle.VehicleType">Class of Vehicle</td>
                                <td class="border" *ngIf="Vehicle.VehicleType"><b>{{Vehicle.VehicleType}}</b></td>
                                <td class="border widthh"></td>
                                <td class="border" *ngIf="Vehicle.VehicleTypes"> Vehicle Name</td>
                                <td class="border" *ngIf="Vehicle.VehicleTypes"><b>{{Vehicle.VehicleTypes}}</b></td>
                                <td class="border widthh"></td>
                                <td class="border" *ngIf="Vehicle.VehicleMake">Manufacturer Name</td>
                                <td class="border" *ngIf="Vehicle.VehicleMake"><b>{{Vehicle.VehicleMake}}</b></td>


                            </tr>

                            <tr *ngIf="Vehicle.VehicleFuelType || Vehicle.RCValidity">
                                <td class="border" *ngIf="Vehicle.VehicleModel">Vehicle MODEL</td>
                                <td class="border" *ngIf="Vehicle.VehicleModel"><b>{{Vehicle.VehicleModel}}</b></td>
                                <td class="border widthh"></td>
                                <td class="border" *ngIf="Vehicle.VehicleFuelType">FUEL</td>
                                <td class="border" *ngIf="Vehicle.VehicleFuelType"><b>{{Vehicle.VehicleFuelType}}</b></td>
                                <td class="border widthh"></td>
                                <td class="border" *ngIf="Vehicle.RCValidity">RC Validity</td>
                                <td class="border" *ngIf="Vehicle.RCValidity"><b>{{Vehicle.RCValidity | date:'dd-MM-yyyy'}}</b></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="row">
                    <div class="col-lg-12">
                        <h3>DOCUMENTS HISTORY:</h3><br />
                    </div>
                    <div class="col-lg-12">
                        <table class="table">
                            <tbody>
                                <tr *ngIf="Vehicle.FitnessValidity || Vehicle.PermitNumber || Vehicle.PermitValidity">
                                    <td class="border" *ngIf="Vehicle.FitnessValidity">FITNESS VALIDITY</td>
                                    <td class="border" *ngIf="Vehicle.FitnessValidity"><b>{{Vehicle.FitnessValidity | date:'dd-MM-yyyy'}}</b></td>
                                    <td class="border widthh"></td>
                                    <td class="border" *ngIf="Vehicle.PermitNumber">PERMIT No</td>
                                    <td class="border" *ngIf="Vehicle.PermitNumber"><b>{{Vehicle.PermitNumber}}</b></td>
                                    <td class="border widthh"></td>
                                    <td class="border" *ngIf="Vehicle.PermitValidity">Permit Validity</td>
                                    <td class="border" *ngIf="Vehicle.PermitValidity"><b>{{Vehicle.PermitValidity | date:'dd-MM-yyyy'}}</b></td>
                                </tr>

                                <tr *ngIf="Vehicle.MeterSealValidity || Vehicle.CNGLPGValidity || Vehicle.EVBatteryValidity">
                                    <td class="border" *ngIf="Vehicle.MeterSealValidity">METER SEAL VALIDITY</td>
                                    <td class="border" *ngIf="Vehicle.MeterSealValidity"><b>{{Vehicle.MeterSealValidity | date:'dd-MM-yyyy'}}</b></td>
                                    <td class="border widthh"></td>
                                    <td class="border" *ngIf="Vehicle.CNGLPGValidity">CNG/LPG TANK VALIDITY</td>
                                    <td class="border" *ngIf="Vehicle.CNGLPGValidity"><b>{{Vehicle.CNGLPGValidity | date:'dd-MM-yyyy'}}</b></td>
                                    <td class="border widthh"></td>
                                    <td class="border" *ngIf="Vehicle.EVBatteryValidity">E.V. BATTERY WARRANTY</td>
                                    <td class="border" *ngIf="Vehicle.EVBatteryValidity"><b>{{Vehicle.EVBatteryValidity | date:'dd-MM-yyyy'}}</b></td>
                                </tr>

                                <tr *ngIf="Vehicle.TaxValidity">
                                    <td class="border" *ngIf="Vehicle.TaxValidity">TAX VALIDITY</td>
                                    <td class="border" *ngIf="Vehicle.TaxValidity"><b>{{Vehicle.TaxValidity | date:'dd-MM-yyyy'}}</b></td>
                                    <td class="border" *ngIf="Vehicle.InsuranceValidity">INSURANCE VALIDITY</td>
                                    <td class="border" *ngIf="Vehicle.InsuranceValidity"><b>{{Vehicle.InsuranceValidity | date:'dd-MM-yyyy'}}</b></td>
                                    <td class="border widthh"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class=" sale-terms ">
                    <h3 class="mt-3">Sale Terms:</h3>
                    <p>
                        1. The seller confirms that the vehicle is free from any finance or loan obligations/with finance.<br />
                        2.	The seller will come to the RTO office physically if required for the vehicle transfer.<br />
                        3. The buyer acknowledges that the vehicle is being purchased in its current condition
                    </p>
                    <h3 class="mt-3"> Additional declarations:</h3>
                    <p>
                        1. The seller has provided all necessary documents and information .<br />
                        2. The seller warrants that the vehicle is free from any RTA, police, traffic costs, or civil and
                        criminal cases.<br />
                        3.	The buyer has inspected the vehicle and is satisfied with its condition.
                    </p>
                    <span style="text-align:end; position:absolute; right:20px;" class="mt-5 mb-n3">P.T.O</span><br />
                </div>

            </div>
          
            <!--<div class="mt-5 "></div>-->
            <div  *ngIf="isGeneratingPDF" style="page-break-before: always;">
                <div class="pb-2"> </div>
                <div class="pdf-header mt-3">
                    <div class="topheader">
                        <h1 style="font-size: 40px; text-align:center; font-family: Arial Black; text-decoration: none;">
                            <b>{{loginDet[0].CompanyName | uppercase}}</b>
                        </h1>
                        <p style="text-align:center; line-height: 1.2rem">
                            <b>{{loginDet[0].CompanyAddress}}</b><br />
                            <b>{{loginDet[0].Mobile}}</b>
                        </p>
                    </div>
                </div>
            </div>


            <!--<div class="additional-decorations">
        <h3> Additional declarations:</h3>
        <p> 1.	The seller has provided all necessary documents and information </p>
        <p> 2.	The seller will come to the RTO office physically if required for the vehicle transfer </p>
        <p> 3.	The buyer has inspected the vehicle and is satisfied with its condition </p>
    </div>-->
            <div class="Finance_Details">
                <h3>Finance Details:</h3>

                <div class="row">
                    <div class="col-lg-9 mt-2 "> 1. Loan Amount (if applicable): </div>
                    <div class="col-lg-3 mt-2 "> <b>{{Vehicle.VehiclePrice}}/-</b> </div>
                    <div class="col-lg-9 mt-2 ">2. Paid EMI: </div>
                    <div class="col-lg-3 mt-2 "><b>{{Vehicle.PaidAmount}}/-</b>. </div>
                    <div class="col-lg-9 mt-2 ">3. Balance EMI:</div>
                    <div class="col-lg-3 mt-2 ">  <b>{{Vehicle.RemainingAmount}}/-</b></div>
                    <div class="col-lg-9 mt-2 ">4. Interest/Late Payment:</div>
                    <div class="col-lg-3 mt-2 "> </div>
                </div>

            </div>
            <div class=" Sale-Consideration">

                <h3>Sale Consideration:</h3>
                <p>
                    The Buyer Pruchased Vehicle From Seller For <b>{{Vehicle.VehiclePrice}}/-</b>
                </p>
            </div>
            <br /> <br />

            <div class="pb-5"></div>
            <div class="pb-5"></div>
            <div class="last">
                <div class="column">
                    <h3 class="ms-3">Seller's Sign</h3>

                </div>

                <div class="column">
                    <h3 class="ms-3">Buyer's Sign</h3>

                </div>

                <div class="column">
                    <h3 class="ms-3">Witness's Sign 1</h3>

                </div>

                <div class="column">
                    <h3 class="ms-3">Witness's Sign 2</h3>

                </div>


            </div>

            <div class="last">
                <div class="column">
                    <h3 class="ms-3">Seller Image</h3>
                    <div class="box">
                        <img [src]="sellerImageUrl" alt="pic" *ngIf="sellerImageUrl; else defaultSellerImage" />
                        <ng-template #defaultSellerImage>

                        </ng-template>
                    </div>
                </div>

                <div class="column">
                    <h3 class="ms-3">Buyer Image</h3>
                    <div class="box">
                        <img [src]="buyerImageUrl" alt="pic" *ngIf="buyerImageUrl; else defaultBuyerImage" />
                        <ng-template #defaultBuyerImage>

                        </ng-template>
                    </div>
                </div>

                <div class="column">
                    <h3 class="ms-3">Witness Image</h3>
                    <div class="box">
                        <img [src]="witnessImageUrl" alt="pic" *ngIf="witnessImageUrl; else defaultWitnessImage" />
                        <ng-template #defaultWitnessImage>

                        </ng-template>
                    </div>
                </div>

                <div class="column">
                    <h3 class="ms-3">Witness Image2</h3>
                    <div class="box">
                        <img [src]="WitnessImage2Url" alt="pic" *ngIf="WitnessImage2Url; else defaultVehicleImage" />
                        <ng-template #defaultVehicleImage>

                        </ng-template>
                    </div>
                </div>


            </div>


            <div class="last">
                <div class="column">
                    <h3 class="ms-3">Vehicle Front</h3>
                    <div class="box">
                        <img [src]="sellerSignatureUrl" alt="pic" *ngIf="sellerSignatureUrl; else defaultSellerImage" />
                        <ng-template #defaultSellerImage>

                        </ng-template>
                    </div>
                </div>

                <div class="column">
                    <h3 class="ms-3">Vehicle Back</h3>
                    <div class="box">
                        <img [src]="buyerSignatureUrl" alt="pic" *ngIf="buyerSignatureUrl; else defaultBuyerImage" />
                        <ng-template #defaultBuyerImage>

                        </ng-template>
                    </div>
                </div>

                <div class="column">
                    <h3 class="ms-3">Vehicle Left</h3>
                    <div class="box">
                        <img [src]="witnessSignatureUrl" alt="pic" *ngIf="witnessSignatureUrl; else defaultWitnessImage" />
                        <ng-template #defaultWitnessImage>

                        </ng-template>
                    </div>
                </div>

                <div class="column">
                    <h3 class="ms-3">Vehicle Right</h3>
                    <div class="box">
                        <img [src]="vehiclePhotoUrl" alt="pic" *ngIf="vehiclePhotoUrl; else defaultVehicleImage" />
                        <ng-template #defaultVehicleImage>

                        </ng-template>
                    </div>
                </div>


            </div>

        </div>

    </div>

    <div class="row">
        <div class="col-lg-8 "></div>
        <div class="col-lg-1 " *ngIf="isGeneratingbutton">
            <button (click)="navigateTOeditform(Fdata[0].RefNo )" class="pdf"><i class="fa fa-edit"></i> Edit</button>
        </div>
        <div class="col-lg-3" *ngIf="isGeneratingbutton">
            <button (click)="downloadPDF()" class="pdf"><i class="fa fa-download"></i> Generate PDF</button>
        </div>
    </div>
</div>

