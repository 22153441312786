import { Component, ViewChild, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule, AbstractControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { GeneralserviceService } from '../../generalservice.service';
import { ElementRef, Renderer2 } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent {
    @ViewChild('myModal3') modalTemplate: TemplateRef<any>;
    public form: FormGroup;
    loginForm: FormGroup;
    HomeUrl: any;
    passwordshow: boolean = false; // Property to toggle password visibility
    public EMailID: any;
    public PWD: AbstractControl;
    passwordShow: boolean = false;
    confirmPasswordShow: boolean = false;
    Pwd: any;
    OTP: any;
    Confirmpassword: any;
    randomnumber: any;
    Phone1: any;
    CheckBox: string;
    Remember: boolean;
    showPassword: boolean = false;

    constructor(
        private fb: FormBuilder,private router: Router,private generalService: GeneralserviceService,
        private http: HttpClient, private elementRef: ElementRef, private renderer: Renderer2,
        private cookieService: CookieService
    ) {
        // Initialize the form using FormBuilder
        this.loginForm = this.fb.group({
            'Mobile/EMail': ['', [Validators.required]],
            Password: ['', [Validators.required]],  // Password is required
        });

        // Fetch the WebService URL
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });

        this.form = this.fb.group({
            Phone1: ['',],
            Password: ['',],
            email: ['',],
        })
    }

    // Method to toggle password visibility
   
    togglePasswordVisibility() {
        this.showPassword = !this.showPassword;
    }
    // Login method to authenticate the user
    login() {
        debugger;
        if (this.loginForm.valid) {
            const loginData = new FormData();

            loginData.append("Param1", this.loginForm.value['Mobile/EMail']);
            loginData.append("Param2", this.loginForm.value.Password);

            const url = "api/FinancierLaw/Roles_Login";

            this.generalService.PostData2(url, loginData).then(
                (data: any) => {
                    console.log('API Response:', data);  // Log the API response

                    // Adjust the condition based on the actual API response structure
                    if (data && data !== 'Invalid User') {
                        localStorage.setItem("LoginDetails", JSON.stringify(data));

                        // Navigate to the home page and reload
                        this.router.navigate(['/1home']).then(() => {
                            window.location.reload(); // Reload the page after navigation
                        });
                    } else {
                        alert("Invalid Mobile/Email or password.");
                        //this.router.navigate(['/RoleSelection'])
                    }
                },
                (error) => {
                    alert("Invalid Mobile/Email or password.");
                }
            );
        } else {
            alert("Invalid Mobile/Email or password.");
        }
    }

    RememberMe(ev) {
        debugger
        if (ev.target.checked == true) {
            this.Remember = true;
            this.cookieService.set('email', this.form.value.Phone1);
            this.cookieService.set('password', this.form.value.Password);
            this.cookieService.set('RememberMe', 'Checked');
            this.CheckBox = 'Checked'
        }
        else if (ev.target.checked == false) {
            // this.cookieService.set('RememberMe', 'UnChecked');
            this.CheckBox = ''
            this.Remember = false;
            this.cookieService.deleteAll();

        }
    }

}
