<div class="container">
    <div class="text-center">
        <!--<p>Let's start on an auspicious note..</p>
        <p>SCHEDULE (C) PAWN BROKERS RULES Inventory of articles taken in pawn as provided</p>
        <p>Sub-Section 11 (1) (b) of A.P.P.B. Act 2002 and rule 12 of A.P.P.B. Rules 2007</p>-->
        <div class="PAGE text-center">
            <h1 class="mtb-2">MAHALAXMI JEWELLERS & PAWN BROKERS</h1>
            <p>#12-7-8, IDL Road, JANATHA NAGAR, Moosapet</p>
            <p>Hyderabad-500 018. TS P.B.Lic. No. 01202</p>
            <p>Cell: 0951080004</p>
        </div>
        <br>
    </div>
    <div class="row">
        <div class="col-md-6">
            <!--<div class="form-group">
        <div class="line">
            <p class="left">Ref No: ____</p>
        </div>

    </div>-->
            <div class="line">
                <p class="left">Ref No : ____</p>
            </div>
            <div class="line">
                <p class="left">Name : _______</p>
            </div>
            <div class="line">
                <p class="left">s/w/d of : _____</p>
            </div>
            <div class="line">
                <p class="left">Cell : _____</p>
            </div>
            <div class="line">
                <p class="left">Address : ____</p>
            </div>
            <div class="line">
                <p class="left">Montly income : ______</p>
            </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <p class="right">Date : ____</p>
                </div>
            </div>
        </div>
    <table class="table table-bordered mt-4">
        <thead>
            <tr>
                <th>No.</th>
                <th>Description of Articles pledged</th>
                <th>Gross Weight</th>
                <th>Net Weight</th>
                <th>Estime Value</th>
                <th>AMOUNT OF LOAN</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        </tbody>

        <tfoot>
            <tr>
                <td></td>
                <td class="text-right"><b>₹</b></td>
                <td></td>
                <td></td>
                <td><b>Total</b></td>
                <td></td>
            </tr>
        </tfoot>
    </table>
        <!--<p>Montly income :_______</p>-->
        <br />

        <p>
            <b>₹ in words</b>______________
        </p>
        <!--card mt-xxl-5-->
        <p class="mb-5 border p-3 rounded">
            INTEREST SHOULD BE PAID EVERY 3 MONTHS WITHOUT FAIL OTHERWISE, COMPOUND INTEREST
            WILL BE CHARGED. ORNAMENTS PLEDGED SHOULD BE REDEEMED BACK WITHIN MONTHS OR
            ELSE. THE ORNAMENTS WILL BE SOLD WITHOUT ANY NOTICE. Interest is to be paid every
            three months.
        </p>
        <p class="signature-lines">
            <span class="left-signature">Signature or L.T.I. of Pawner</span>
            <span class="right-signature">Signature of Pawn Broker or Agent</span>
        </p>
        <!--<div class="terms">
        <h3> Terms and Conditions:</h3>
        <p>
            <b> 1.Address Accuracy: </b>
            The postal address provided by the pawner is correct. Any change in address should be immediately communicated to the pawner.
        </p>
        <p><b>2.Redemption Period:  </b> The maximum redemption period for pledged items is 12 months from the date of pledge. Failure to redeem within this period may result in the items being sold or auctioned without notice, and the pawner will forfeit any claims on these items.</p>
        <p><b>3.Token Responsibility: </b> The pawner must preserve the token issued for the pledged commodities. If the token is lost, a duplicate can be issued upon submission of a written request and payment of Rs. 100.</p>
        <p><b>4.Redemption Notice: </b> The pawner is required to provide at least 24 hours' notice for the release of pledged items upon full payment of the loan amount, accrued interest, and any other applicable fees.</p>
        <p><b>5.Interest Payment: </b> Interest must be paid every 3 months without fail. Failure to pay on time will result in the application of compound interest.</p>
        <p><b> 6.Loss or Damage: </b> If the pledged items are lost or damaged due to unforeseen circumstances, the pawner will be compensated only up to the value specified at the time of pledge.</p>
        <p><b>7.Ownership: </b> The pawner affirms that the pledged items are their property, free of any third-party claims.</p>
        <P><b> 8.Hours of Operation: </b> Business hours are from 9:00 a.m. to 6:30 p.m. The shop remains closed on Tuesdays, public holidays, and specific festivals.</P>
        <p><b> 9.Redemption After 12 Months: </b>If the pledged articles are not redeemed within 12 months, the pawner reserves the right to sell them, and the pawner will have no claims over them.</p>
        <p><b> 10.Non-Redeemable after Hours: </b> Items cannot be redeemed after 6:00 p.m.</p>
        <p><b>11.Principal and Interest Clearance: </b> All dues, including principal and interest, must be cleared before the release of pledged articles.</p>
        <b> Note: </b>These terms and conditions are agreed upon by the pawner and the pawnbroker. Any violation may result in legal proceedings as per local pawn and money lending laws.
    </div>-->
        <div class="terms">

            <h3> Terms & Conditions : </h3>
            <br/>
            <p class="mb-1">1. The postal address given overleaf is correct.  </p>
            <p class="mb-1">2. That i will inform the pawnee forth with of any change in my address. </p>
            <p class="mb-1">3. That the maximum period, available for redemption is 11 months from the date of pledge as agreed to between the parties.</p>
            <p class="mb-1">4. That i am bound to preserve the token issued to me by the pawnee in respect of the said commodities. </p>
            <p class="mb-1">5. That i understand that the said commodities listed overleaf will not returned to me unless produce the said token. </p>
            <p class="mb-1">6. That in case the token is lost i am entitled to the issue of another token on submission of letter and on sum Rs.5/-. </p>
            <p class="mb-1">7. That on expiry of the aforesaid period of redemption the pawnee is at liberty to self / auction the said commodities & shall cease to have any claim what so ever over the same.</p>
            <p class="mb-1">8. That i am bound to give 24 hours prior notice to the pawnee for release of the said commodities after pay in all amounts due to the pawnee towards amount borrowed, interest accured there on together with legal / miscellaneous expenses incurred, if any. </p>
            <p class="mb-1">9. That in case the said commodities listed overleaf are lost for any reason what so ever, I shall be entitled to receive only the sums specified against item No.7(3) overleaf towards their value.</p>
            <p class="mb-1">10. That the commodities listed overleaf are my own and no other person has got any right the same. </p>
            <p class="mb-1">11. That on expiry of the aforesaid period of redemption the pawnee is at liberty to self / auction the said commodities & shall cease to have any claim what so ever over the same.</p>
        </div>


        <div class="image-row">
            <div class="image-container">
                <img src="assets/img/ornaments.jpg" alt="img1" class="center-image">
                <p>pawnbrokerPhoto</p>
            </div>
            <div class="image-container">
                <img src="assets/img/ornaments.jpg" alt="img2" class="center-image">
                <p>borrowerPhoto</p>
            </div>
            <div class="image-container">
                <img src="assets/img/ornaments.jpg" alt="img3" class="center-image">
                <p>witnessPhoto1</p>
            </div>
            <div class="image-container">
                <img src="assets/img/ornaments.jpg" alt="img4" class="center-image">
                <p>witnessPhoto2</p>
            </div>
        </div>
        <div class="image-row">
            <div class="image-container">
                <img src="assets/img/bangles.webp" alt="img5" class="center-image">
                <p>jewelleryPhoto1</p>
            </div>
            <div class="image-container">
                <img src="assets/img/earnings.jpg" alt="img6" class="center-image">
                <p>jewelleryPhoto2</p>
            </div>
            <div class="image-container">
                <img src="assets/img/ornaments.jpg" alt="img7" class="center-image">
                <p>jewelleryPhoto3</p>
            </div>
            <div class="image-container">
                <img src="assets/img/bangles1.webp" alt="img8" class="center-image">
                <p>jewelleryPhoto4</p>
            </div>
        </div>
        <p>
            <b>NOTE:</b> BUSINESS HOURS FROM 10-30 a.m. To 6-30 p.m. Sunday, Jain Festivals and Amavasya, Shop is Strictly Closed. Schedule (F) Money Lenders Rules.
        </p>
        <br />
        <p class="signature-line">
            <span class="left-signature">Signature of the Pawner</span>
            <span class="right-signature">Money Lender / Agent</span>
        </p>
        <span class="left-signature">Date : ___________</span>
</div>


