import { Component } from '@angular/core';

@Component({
    selector: 'app-seller',
   
    templateUrl: './seller.component.html',
    styleUrl: './seller.component.scss'
})
export class SellerComponent {

}

