<!--4th Form-->
<div class="ptb-100">
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center ">
            <br />
            <h6 class="text-white display-3 mb-4 wow fadeInDown" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInDown;">
                Loan Rescheduling Request
            </h6>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInDown;">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active text-white">Loan Rescheduling Request</li>
            </ol>
        </div>
    </div>
    <br /><br /><div style="margin-top:60px"></div>
    <div id="pdf-content" class=" mt-5">
        <div class="container-fluid pt-3 content">
            <div class="container">
                <div class="col-lg-12">
                    <h2 class="surr">
                        <b style="font-size:30px">Loan Rescheduling Request</b>
                    </h2>
                    <br />
                    <br />
                </div>   <div class="col-lg-12 border-top1 border-top2"></div><br />

                <div>
                    <p>TO,</p>
                    <p><strong>{{loginDet[0].Name}}</strong><br>{{loginDet[0].CompanyAddress}}</p>

                    <p>Respected Sir/Madam,</p>

                    <p><strong>Subject:</strong> Loan Rescheduling Request</p>

                    <p>I, <strong>{{Fdata[0].Name}}</strong>, son of <strong>{{Fdata[0].FatherName}}</strong>,</p>

                    <div class="row">
                        <div class="col-md-8">
                            <ul class="list-unstyled">
                                <li> Age: </li>
                                <li> Occupation: </li>
                                <li> Residence: </li>
                            </ul>
                        </div>
                        <div class="col-md-4">
                            <ul class="list-unstyled">
                                <li><strong>{{Fdata[0].Age}} Years</strong></li>
                                <li><strong>{{Fdata[0].Occupation}}</strong></li>
                                <li><strong>{{Fdata[0].Address}}</strong></li>
                            </ul>
                        </div>
                    </div>

                    <p style="text-decoration:underline"><strong>Vehicle Details:</strong></p>
             
  <div class="row">
    <div class="col-md-8">
      <ul class="list-unstyled">
        <li>Vehicle Registration Number: </li>
        <li> Make: </li>
      </ul>
    </div>
    <div class="col-md-4">
      <ul class="list-unstyled">
        <li><strong>{{Fdata[0].VehicleRegno}}</strong></li>
        <li><strong>{{Fdata[0].Vehiclemake}}</strong></li>
      </ul>
    </div>
  </div>
 

                    <p style="text-decoration:underline"><strong>Loan Details:</strong></p>
               
  <div class="row">
    <div class="col-md-9">
      <ul class="list-unstyled">
        <li>Date of Agreement: </li>
        <li> Loan Amount including Interest and Late Fee Total Amount: </li>
        <li> Total Amount Payable: </li>
        <li> Balance Amount: </li>
      </ul>
    </div>
    <div class="col-md-3">
        <ul class="list-unstyled">
            <li><strong>{{ Fdata[0].Agreementdate | date: 'dd-MM-yyyy' }}</strong></li>
<li><strong>Rs.{{Fdata[0].TotalAmountwInterest}}/-</strong></li>
            <li><strong>Rs.{{Fdata[0].TotalAmount}}/-</strong></li>
            <li><strong>Rs.{{Fdata[0].Balance}}/-</strong></li>
        </ul>
    </div>
  </div>
 


                    <p style="text-decoration:underline"><strong>Request:</strong></p>
                    <p>Due to unforeseen circumstances, I am facing difficulties in repaying the loan through EMIs. I respectfully request you to consider Amount of Rs.<strong>{{Fdata[0].RepayingAmount}}/-</strong> rescheduling/ renegotiating.</p>
                    <!--the loan of Rs.<strong>{{Fdata[0].RescheduleAmount}}/-</strong>.</p>-->
                
                    <p>I assure you that I will regularize my EMI payments in the future.</p>

                    <p style="text-decoration:underline"><strong>Declaration:</strong></p>
                    <p>I confirm that the information provided is true and accurate.</p>

                    <div class="row">
                        <div class="col-lg-8 mt-3">
                            <div>Signature:</div><br>
                            <p>
                                Guarantor/Surety Name:

                                <strong> {{Fdata[0].SuretyName}}</strong>
                            </p>
                            <br>
                            <div>Mobile: <strong> {{Fdata[0].Suretymobile}}</strong></div>
                        </div>
                        <div class="col-lg-4 text-end mt-3">
                            <div>Signature:</div> <br>
                            <div>Date:<strong>{{ Fdata[0].CreatedDate | date: 'dd-MM-yyyy' }}</strong> </div>
                            <br>  <div>Mobile: <strong>{{Fdata[0].Mobile}}</strong></div>
                        </div>





                    </div>
                </div>


            </div>

        </div><br /> <br />
        <div class="container">
            <div class="col-lg-12"></div>
        </div>

    </div>
</div>
<div class="row">
    <div class="col-lg-8 ">
    </div>
    <div class="col-lg-1 ">
        <button (click)="navigateTOeditform(Fdata[0].Lid )" class="pdf"><i class="fa fa-edit"></i> Edit  </button>
    </div>
    <div class="col-lg-3">
        <button (click)="downloadPDF()" class="pdf"><i class="fa fa-download"></i> Generate PDF  </button>
    </div>
</div>
