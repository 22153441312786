
<div class="pt-100 bgblue">
    <br />
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center ">
            <h6 class="text-white display-3 mb-4 wow fadeInDown" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInDown;">
                Absconding List
            </h6>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInDown;">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active text-white"> Absconding List</li>
            </ol>
        </div>
    </div>
    <div class="container mt-5 box-container">
        <div class="col-lg-12">
            <div class="row">
                <div class="col-lg-3">
                    <!-- Uncomment if you need the add button -->
                    <!--<a href="/Abscondingform" class="btn btn-primary" title="Add New Absconding Form"> + Add </a>-->
                </div>

                <div class="col-lg-3"></div>
                <div class="col-lg-6">

                    <div class="input-wrapper">
                        <i class="fa fa-search search-icon"></i> <!-- Search icon at the start -->

                        <input [(ngModel)]="Adscandinglist"
                               (input)="abscandFilter()"
                               class="searchh"
                               style="width:100%;"
                               placeholder="Search Name/Mobile/VehicleNo" />

                        <div *ngIf="Adscandinglist"
                             class="close-btn"
                             (click)="clearSearch()">X</div>
                    </div>

                </div>
            </div>


            <div class="box-content" style="overflow-x: auto;">

                <table class="table-hover" border="1" cellpadding="10" cellspacing="0" style="width: 200%; min-width: 700px;">
                    <thead>
                        <tr style="border-bottom:none;">
                            <!--<th class="custom_border" *ngIf="LoginDetails && (LoginDetails[0]?.RoleID !== 4 && LoginDetails[0]?.RoleID !== 5)">Action</th>-->
                            <th class="custom_border">Borrower Name</th>
                            <th class="custom_border">Borrower Father Name</th>
                            <th class="custom_border">Borrower Mobile Number</th>
                            <th class="custom_border">Borrower Address</th>
                            <!--<th class="custom_border">State Name</th>
                    <th class="custom_border">District Name</th>-->
                            <th class="custom_border">
                                <label>Select State &nbsp;  </label>
                               
                            </th>
                            <th class="custom_border">
                                <label for="district">Select District &nbsp;  </label>
                              
                            </th>
                            <th class="custom_border">
                                Vehicle Number
                               
                            </th>
                            <th class="custom_border">Vehicle Engine Number</th>

                            <th class="custom_border">Vehicle Chassis Number</th>
                            <th class="custom_border">
                                Financer Name
                                <!-- Dropdown filter for Financer Name -->
                                
                            </th>
                            <th class="custom_border">Prize Money</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of AbscandData">
                       
                            <td>{{item.BorrowerName}}</td>
                            <td>{{item.BorrowerFatherName}}</td>
                            <td>{{item.BorrowerMobileNumber}}</td>
                            <td>{{item.BorrowerAddress}}</td>
                            <td>{{item.StateName}}</td>
                            <td>{{item.DistrictName}}</td>
                            <td>{{item.VehicleNumber}}</td>
                            <td>{{item.VehicleEngineNumber}}</td>
                            <td>{{item.VehicleChassisNumber}}</td>
                            <td>{{item.FinancerName}}</td>
                            <td>{{item.PrizeMoney}}</td>
                        </tr>

                    </tbody>
                </table>






            </div>
            <div class="pagination-controls">
                <button class="pagi" (click)="PreviousABSPage()" [disabled]="currentPage === 1"> <<<</button>
                <span> {{currentPage}} </span>
                <button class="pagi" (click)="NextABSPage()" [disabled]="currentPage === totalPages">>>></button>
            </div>
        </div>
    </div>
</div>
