import { Component } from '@angular/core';
import { GeneralserviceService } from '../../generalservice.service';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import * as html2pdf from 'html2pdf.js';

import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-recoverymanager',
  templateUrl: './recoverymanager.component.html',
  styleUrl: './recoverymanager.component.scss'
})
export class RecoverymanagerComponent {
    Fdata: any;
    BarrowerAge: any;
    BarrowerWorkingDays: any;
    BarrowerOccupation: any;
    BarrowerName: any;
    BarrowerAddress: any;
    BarrowerMobile: any;
    BarrowererSonOf: any;
    BarrowerType: any; BarrowerRegNo: any; Noticeid: any; RecovererName: any;
    BarrowerFatherName: any;
    Recovererfather: any; Recovererage: any;
    VehicleType: any;
    VehicleRegNo: any; RefNumber: any; TodayDate: any;
    HomeUrl: any; pdf: any;
    imagefile2: string | ArrayBuffer;
    TypeImage: string | ArrayBuffer;
    wheeltype: any; loginDet: any; RecovererOccupation: any;
    constructor(public generalService: GeneralserviceService,
        public http: HttpClient, public router: Router,
        public activeroute: ActivatedRoute) {

        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));

        this.Noticeid = localStorage.getItem("noticeid");

        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const yyyy = today.getFullYear();

        this.TodayDate = `${dd}-${mm}-${yyyy}`;

        this.wheeltype = localStorage.getItem("wheeltype");
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });

    }


    ngOnInit() {


        this.Getforms();
    }

    Getforms() {
        debugger;
        const uploadfile = new FormData();
        uploadfile.append('Param', this.Noticeid); // Corrected parameter interpolation

        const url = "api/FinancierLaw/FA_NoticeForms_basedon_ARGNO";
        this.generalService.Postdata1(url, uploadfile).subscribe(
            data => {
                debugger;
                this.Fdata = data;
                this.RecovererName = this.Fdata[0].RecovererName,
                this.Recovererage = this.Fdata[0].RecovererAge,
                this.Recovererfather = this.Fdata[0].RecovererSonOf,
                    this.RecovererOccupation = this.Fdata[0].RecoveryOccupation,

                // Assign properties from Fdata
                this.BarrowerAge = this.Fdata[0].BarrowerAge;
                this.BarrowerWorkingDays = this.Fdata[0].SellWithInDays;
                this.BarrowerOccupation = this.Fdata[0].BarrowerOccupation;
                this.BarrowerName = this.Fdata[0].BorrowerName;
                this.BarrowerAddress = this.Fdata[0].BarrowerAddress;
                this.BarrowerMobile = this.Fdata[0].BarrowerMobile;
                this.VehicleType = this.Fdata[0].VehicleType;
                this.VehicleRegNo = this.Fdata[0].VehicleRegNo;
                this.BarrowerFatherName = this.Fdata[0].FatherName
                this.RefNumber = this.Fdata[0].RefNo
                // Add other fields as needed based on Fdata
            },
            err => {
                // Uncomment the following line if you want to show an alert on error
                // this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
                console.error('Error:', err); // Added logging for debugging
            }
        );
        console.log('ha', this.Fdata)

    }

    navigateTOeditform(aggrementno: any) {
        debugger
        this.router.navigate(['/Recoverymanagerform'], { queryParams: { AggrementNo: aggrementno } });
        localStorage.setItem('Agreement', aggrementno);
    }



    //downloadPDF() {
    //    debugger
    //    const element = document.getElementById('pdf-content');
    //    localStorage.removeItem("noticeid");
    //    localStorage.removeItem("wheeltype");
    //    if (!element) {
    //        console.error('Element not found');
    //        return;
    //    }

    //    // Temporarily adjust styles for PDF generation
    //    const originalStyle = {
    //        fontSize: element.style.fontSize,
    //        lineHeight: element.style.lineHeight,
    //        padding: element.style.padding
    //    };

    //    element.style.fontSize = '16px'; // Adjust as needed
    //    element.style.lineHeight = '1'; // Adjust as needed
    //    element.style.padding = '0'; // Remove padding if needed

    //    // Temporarily adjust paragraph spacing
    //    const paragraphs = element.getElementsByTagName('p');
    //    for (let i = 0; i < paragraphs.length; i++) {
    //        paragraphs[i].style.margin = '0'; // Remove margin
    //        paragraphs[i].style.padding = '0'; // Remove padding
    //    }

    //    const today = new Date();
    //    const dd = String(today.getDate()).padStart(2, '0');
    //    const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    //    const yyyy = today.getFullYear();
    //    const hours = String(today.getHours()).padStart(2, '0');
    //    const minutes = String(today.getMinutes()).padStart(2, '0');
    //    const seconds = String(today.getSeconds()).padStart(2, '0');

    //    const dateTimeString = `${dd}-${mm}-${yyyy}-${hours}:${minutes}:${seconds}`;
    //    const filename = `RecoveyForm_${this.Noticeid}.pdf`;

    //    // Example of directory path where the file might be saved (for demonstration)
    //    const filePath = `C:/Users/YourUsername/Downloads/${filename}`;

    //    // Set PDF options
    //    const options = {
    //        margin: [0, 0.5, 0.5, 0.5], // Adjust margins if needed
    //        filename: filename,
    //        image: { type: 'jpeg', quality: 0.98 },
    //        html2canvas: { scale: 1 },
    //        jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
    //    };

    //    // Convert HTML to PDF
    //    html2pdf()
    //        .from(element)
    //        .set(options)
    //        .save()
    //        .then(() => {
    //            // Revert the styles for the front-end
    //            element.style.fontSize = originalStyle.fontSize;
    //            element.style.lineHeight = originalStyle.lineHeight;
    //            element.style.padding = originalStyle.padding;

    //            // Revert paragraph spacing
    //            for (let i = 0; i < paragraphs.length; i++) {
    //                paragraphs[i].style.margin = ''; // Revert margin
    //                paragraphs[i].style.padding = ''; // Revert padding
    //            }
    //            alert('Your pdf has been downloaded successfully to your downloads folder.');
    //        });

    //    html2pdf().from(element).set(options).toPdf().outputPdf('blob').then((pdfBlob) => {
    //        // Create a file-like object from the Blob
    //        const file = new File([pdfBlob], filename, { type: 'application/pdf' });
    //        this.autoUpload(file);

    //        // Output the file path
    //        console.log(`PDF saved at: ${filePath}`);
    //    });
    //}
    downloadPDF() {
        const element = document.getElementById('pdf-content');
        if (!element) {
            console.error('Element not found');
            return;
        }

        // Temporarily adjust styles for PDF generation
        const originalStyle = {
            fontSize: element.style.fontSize,
            lineHeight: element.style.lineHeight,
            padding: element.style.padding,
            margin: element.style.margin,
            border: element.style.border,
            boxShadow: element.style.boxShadow
        };

        // Reset styles to ensure plain content appearance
        element.style.fontSize = '12px'; // Normal font size
        element.style.lineHeight = '1.4'; // Line height for readability
        element.style.padding = '0'; // No padding
        element.style.margin = '0'; // No margin
        element.style.border = 'none'; // Remove border
        element.style.boxShadow = 'none'; // Remove box shadow

        // Adjust paragraph styles
        const paragraphs = element.getElementsByTagName('p');
        for (let i = 0; i < paragraphs.length; i++) {
            const paragraphElement = paragraphs[i] as HTMLElement;
            paragraphElement.style.margin = '0 0 8px 0'; // Add bottom margin for spacing
            paragraphElement.style.padding = '0'; // Remove padding
        }

        // Adjust list item styles to match paragraph formatting
        const listItems = element.getElementsByTagName('li');
        for (let i = 0; i < listItems.length; i++) {
            const listItemElement = listItems[i] as HTMLElement;
            listItemElement.style.margin = '0'; // Remove margin
            listItemElement.style.padding = '0'; // Remove padding
            listItemElement.style.listStylePosition = 'inside'; // Ensure list items are formatted well
        }

        // Add spacing below side headings
        const headings = element.querySelectorAll('h1, h2, h3, h4, h5, h6');
        for (let i = 0; i < headings.length; i++) {
            const headingElement = headings[i] as HTMLElement;
            headingElement.style.marginBottom = '8px'; // Add space below headings
        }

        // Add spacing above the last address block
        const addressElement = document.getElementById('address');
        if (addressElement) {
            addressElement.style.marginTop = '16px'; // Add space above the address
        }

        // Get current date and time to append to filename
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const yyyy = today.getFullYear();
        const hours = String(today.getHours()).padStart(2, '0');
        const minutes = String(today.getMinutes()).padStart(2, '0');
        const seconds = String(today.getSeconds()).padStart(2, '0');

        const dateTimeString = `${dd}-${mm}-${yyyy}-${hours}:${minutes}:${seconds}`;
        const filename = `RecoveryForm_${dateTimeString}.pdf`;

        // Configure PDF options
        const options = {
            margin: [0.5, 1, 0.5, 1], // Adjust margins if needed
            filename: filename,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 1, logging: true, useCORS: true }, // Enable CORS for external images
            jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
        };

        // Use html2pdf to convert the content to a PDF
        html2pdf()
            .from(element)
            .set(options)
            .save()
            .then(() => {
                // Revert the styles for the front-end
                element.style.fontSize = originalStyle.fontSize;
                element.style.lineHeight = originalStyle.lineHeight;
                element.style.padding = originalStyle.padding;
                element.style.margin = originalStyle.margin;
                element.style.border = originalStyle.border;
                element.style.boxShadow = originalStyle.boxShadow;

                for (let i = 0; i < paragraphs.length; i++) {
                    const paragraphElement = paragraphs[i] as HTMLElement;
                    paragraphElement.style.margin = ''; // Revert margin
                    paragraphElement.style.padding = ''; // Revert padding
                }

                for (let i = 0; i < listItems.length; i++) {
                    const listItemElement = listItems[i] as HTMLElement;
                    listItemElement.style.margin = ''; // Revert margin
                    listItemElement.style.padding = ''; // Revert padding
                }

                for (let i = 0; i < headings.length; i++) {
                    const headingElement = headings[i] as HTMLElement;
                    headingElement.style.marginBottom = ''; // Revert margin-bottom
                }

                if (addressElement) {
                    addressElement.style.marginTop = ''; // Revert margin-top
                }

                alert('Your PDF has been downloaded successfully.');
            });
    }














    autoUpload(file: File) {
        debugger
        const reader = new FileReader();
        reader.onload = () => {
            this.imagefile2 = reader.result;
            this.TypeImage = this.imagefile2;
        };
        reader.readAsDataURL(file);

        const selectedFile = file;
        const idxDot = selectedFile.name.lastIndexOf(".") + 1;
        const extFile = selectedFile.name.substr(idxDot, selectedFile.name.length).toLowerCase();
        if (extFile === "jpg" || extFile === "jpeg" || extFile === "png" || extFile === "pdf" || extFile === "webp") {
            const UploadFile = new FormData();
            UploadFile.append("pdf", file);
            const url = 'api/FinancierLaw/Uploadformpdf';
            this.generalService.Postdata1(url, UploadFile).subscribe((data: any) => {
                if (data != null) {
                    debugger
                    this.pdf = data;
                    this.Insertpdfpath()
                }
            });
        } else {
            alert("Only jpg/jpeg, png, gif, and webp files are allowed!");
        }
    }

    Insertpdfpath() {
        debugger;
        const uploadfile = new FormData();
        uploadfile.append('Param1', this.pdf);
        uploadfile.append('Param2', this.Noticeid);
        var url = "api/FinancierLaw/FLA_update_Formpdfpath_basedon_ARGNO";
        this.generalService.Postdata1(url, uploadfile).subscribe(
            data => {
                debugger;

                if (data == 'SUCCESS') {
                    this.generalService.ShowAlert('Success', 'pdf download successful', 'success');

                }

            },
            err => {
                this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
            }
        );
        this.router.navigate(['/1home'])
    }
}
