<div class="topnav">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 topnav1">
                <ul>
                    <li style="float:left">
                        <a href="tel:+919381417022" target="_blank" style="color:white; font-size:13px;cursor:pointer;">
                            <i class="fas fa-phone" style="font-size:13px;"></i>+91 9381417022
                        </a>
                    </li>
                     
                    <li>
                        <a class="" href="mailto:financeradvise2024@gmail.com" target="_blank" style="color: white;margin-top: 3px; cursor: pointer;font-size:13px;float:left ">
                          &nbsp;  <i class="fa fa-envelope"
                               aria-hidden="true"></i>&nbsp;
                            financeradvise2024&#64;gmail.com
                        </a>&nbsp;
                    </li>
                </ul>

            </div>


            <div class="col-lg-5">
                <!--<input [(ngModel)]="seracingdata" (input)="searchdata()" class="searchh"style="width:100%;" placeholder="Search" />
    <div class="close searchh" (click)="clearSearch()">X</div>-->
                <div class="input-wrapper">
                    <i class="fa fa-search search-icon"></i> <!-- Search icon at the start -->

                    <input [(ngModel)]="seracingdata"
                           (input)="searchdata()"
                           class="searchh"
                           style="width:100%;"
                           placeholder="Search" />

                    <div *ngIf="seracingdata"
                         class="close-btn"
                         (click)="clearSearch()">X</div>
                </div>


                <div class="card search-results-container">
                    <div style="border: 1px solid #cfcccc;padding: 0px 10px;" *ngFor="let item of Searchingusers">
                        {{item.BorrowerName}},&nbsp;{{item.VehicleRegNo}},{{formatStatus(item.VehicleBlocked)}}
                    </div>
                </div>

            </div>
            <div class="col-lg-2 topnav1">
                <ul>
                    <li style=" display: inline-block; float: right">
                        <a href="https://api.whatsapp.com/send?phone=919874563210&text=%20Hello%20Financier%20Team&source=&data=%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20target=" target="_blank" style="color:white;">
                            <i class="fab fa-whatsapp iconss"></i>
                        </a>
                    </li>
                    <li style=" display: inline-block; float: right">
                        <a href="https://www.facebook.com/" target="_blank" style="color: white;    ">
                            <i class="fab fa-facebook-f  iconss"></i>
                        </a>
                    </li>
                    <li style=" display: inline-block; float: right">
                        <a href="https://twitter.com/" target="_blank" style="color: white;    ">
                            <i class="fab fa-twitter iconss"></i>
                        </a>
                    </li>
                </ul>

             
            </div>
        </div>
    </div>
</div>

<nav
    class="navbar navbar-expand-lg navbar-light bg-light"
    [class.active]="classApplied"
    [ngClass]="{'sticky': isSticky}"
>
    <div class="container">

        <a class="navbar-brand" (click)="onCardClick1()">
             <img src="../assets/img/AppLogo.png"  alt="apps1"  class="navbar-logo">
             <span style="text-transform: capitalize;">Financier Legal App</span>
        </a>

        <button class="navbar-toggler" type="button" (click)="toggleClass()">
            <span class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
        </button>
        <div class="collapse navbar-collapse navvbarr" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <!--<li class="nav-item"><span class="nav-link" routerLink="/contact">Contact Us</span></li>-->

                <li class="nav-item"><span class="nav-link" (click)="onCardClick1()">Home</span></li>
                <li class="nav-item"><span class="nav-link" routerLink="/AboutUs">About</span></li>
                <!--<li><span routerLink="/searchrefrence"> sr </span></li>-->
                <li class="nav-item"><span class="nav-link" routerLink="/Subcription">Subscription</span></li>
                <li class="nav-item"><span class="nav-link" [routerLink]="['/home']" fragment="section1">How It Works</span></li>
                <li class="nav-item" *ngIf="LoginDet; else showLogin">
                    <span class="nav-link" routerLink="Myprofile" style="font-weight: bold; color: brown;">
                        {{ LoginDet[0].Name }}
                    </span>
                </li>

                <li class="nav-item" *ngIf="LoginDet">
                    <span class="nav-link" (click)="logout()">Logout</span>
                </li>
                <ng-template #showLogin>
                    <li class="nav-item">
                        <span class="nav-link" routerLink="/Login">Login</span>
                    </li>
                    <!--<li class="nav-item">
                        <span class="nav-link" routerLink="/RoleSelection">Registration</span>
                    </li>--> <li class="nav-item">
                        <span class="nav-link" routerLink="/Abscondinglist">Absconding List</span>
                    </li>
                    <li class="nav-item">
                        <span class="nav-link" routerLink="/Blocklist">Black list</span>
                    </li>
                </ng-template>

                <li class="nav-item"><span class="nav-link" routerLink="/ContactUs">Contact</span></li>
                <!--<li>
                    <input [(ngModel)]="seracingdata" (input)="searchdata()"  class="searchh" placeholder="Search" />
                    <button class="close searchh" (click)="clearSearch()">X</button>
                    <div class="card" *ngFor="let item of Searchingusers">
                        {{item.BorrowerName}},&nbsp;{{item.VehicleRegNo}},{{formatStatus(item.VehicleBlocked)}}
                    </div>
                </li>-->
            

            </ul>
            
            <ul class="navbar-nav ml-auto for-responsive">
                <!--<li class="nav-item"><span class="nav-link" routerLink="/">Home</span></li>
                <li class="nav-item"><span class="nav-link" routerLink="/About Us">About</span></li>
                <li class="nav-item"><span class="nav-link" routerLink="/Subcription">Subscription</span></li>
                <li class="nav-item"><span class="nav-link" routerLink="/About Us">Contact</span></li>-->

                <li class="nav-item"><span class="nav-link" (click)="onCardClick1()">Home</span></li>
                <li class="nav-item"><span class="nav-link" routerLink="/AboutUs">About</span></li>
                <!--<li><span routerLink="/searchrefrence"> sr </span></li>-->
                <li class="nav-item"><span class="nav-link" routerLink="/Subcription">Subscription</span></li>
                <li class="nav-item"><span class="nav-link" href="#section1">How It Works</span></li>
                <li class="nav-item" *ngIf="LoginDet; else showLogin">
                    <span class="nav-link" routerLink="" style="font-weight: bold; color: brown;">
                        {{ LoginDet[0].Name }}
                    </span>
                </li>

                <li class="nav-item" *ngIf="LoginDet">
                    <span class="nav-link" (click)="logout()">Logout</span>
                </li>
                <ng-template #showLogin>
                    <li class="nav-item">
                        <span class="nav-link" routerLink="/Login">Login</span>
                    </li>
                    <li class="nav-item">
                        <span class="nav-link" routerLink="/RoleSelection">Registration</span>
                    </li>
                </ng-template>

                <li class="nav-item"><span class="nav-link" routerLink="/ContactUs">Contact</span></li>

            </ul>
        </div>
    </div>
</nav>
