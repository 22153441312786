<div class="container pt-5 mt-5">
    <div class="topheader">
        <h1 style="font-size: 27px; font-family:airal black; text-decoration:none;">SR AUTO CONSULTANTS & COMMISSION AGENT</h1>
        <p style="text-align:center; line-height: 1.2rem"> #49, kushal Tower, M.Piller No.A-1188,<br /> Khairtabad, Hyderabad,<br />Pin No: 500004.<br />9871658973</p>
    </div><br />
    <div class="header">
        <h1>PURCHASE RECEIPT / AGREEMENT</h1>
        <!--<p style="text-align:center"> #49, kushal Tower, M.Piller No.A-1188,<br /> Khairtabad, Hyderabad-500 004</p>-->
        <!--<p style="float:right">Date: <span class="blank-line"></span></p>--> <!--<p style="float:right">Date: <span class="blank-line"></span></p>-->
        <p class="float-end">Date:----</p><br />
    </div>
    <div class="buyer-details">
        <h3>Buyer Details:</h3>
        <p>
            Name:  <span class="blank-line"></span>,
            Father's Name:<span class="blank-line"></span>,
            Age:<span class="blank-line"></span>,
            Occupation:  <span class="blank-line"></span>,
            Address:  <span class="blank-line"></span>,
            H. No -----, Street No -------, Colony Name:---------, Mandal__________, State__________, Pin code:__________,
            Phone No <span class="blank-line"></span>.
        </p>
    </div>
    <br />
    <div class="seller-details:">
        <h3>Seller's Details:</h3>
        <p>
            Name: <span class="blank-line"></span>,
            Father's Name: <span class="blank-line"></span>,
            Age:  <span class="blank-line"></span>,
            Occupation:  <span class="blank-line"></span>,
            Address: <span class="blank-line"></span>,
            H. No: <span class="blank-line"></span>,
            Street No: <span class="blank-line"></span>,
            Colony Name:<span class="blank-line"></span>,
            Mandal: <span class="blank-line"></span>,
            State: <span class="blank-line"></span>,
            Pin code: <span class="blank-line"></span>,
            Phone No: <span class="blank-line"></span>.
        </p>
    </div><br />
    <div class="vehicle-details">
        <h3>Vehicle Details:</h3>
        <p>
            Registration Number: <span class="blank-line"></span>,   Chassis   Number:  <span class="blank-line"></span>,   Engine<br />

            Number: <span class="blank-line"></span>, Vehicle Type: [Car/Bike/Auto/Other]	 <span class="blank-line"></span><br />

            TRANSPORT/NON TRANSPORT <span class="blank-line"></span> MODEL <span class="blank-line"></span>FUEL <span class="blank-line"></span><br />
            PETROL/DIESEL/LPG/CNG/EV/OTHER <span class="blank-line"></span>,RC Validity<span class="blank-line"></span>
        </p>
    </div><br />
    <div class="DOCUMENTS-HISTORY" style="position: relative">
        <h3>
            DOCUMENTS HISTORY
        </h3>
        <p>
            FITNESS VALIDITY <span class="blank-line"></span>
            PERMIT VALIDITY <span class="blank-line"></span><br />
            METER SEAL VALIDITY <span class="blank-line"></span>
            CNG/LPG TANK VALIDITY <span class="blank-line"></span>
            E. V. BATTERY-----------
            WARRANTY <span class="blank-line"></span>
            TAX VALIDITY <span class="blank-line"></span>
        </p>

        <span style="text-align:end;position:absolute;right:20px;">P.T.O</span><br />
    </div>
    <p>-------------------------------------------------------------------------</p>
    <div class="Delivery-Note">
        <h3 style="text-align:center;">PART-<span style=" font-weight: 500; font-family: 'Poppins';"><b>&#8545;</b></span></h3>
    </div>
    <div class="sale-terms">
        <h3>Sale Terms:</h3>
        <p>
            1. The seller confirms that the vehicle is free from any finance or loan obligations/with finance.<br />
            2. The seller warrants that the vehicle is free from any RTA, police, traffic costs, or civil and
            criminal cases.     <br />
            3. The buyer acknowledges that the vehicle is being purchased in its current condition
        </p>
    </div><br />
    <div class="additional-decorations">
        <h3> Additional declarations:</h3>
        <p> 1.	The seller has provided all necessary documents and information </p>
        <p> 2.	The seller will come to the RTO office physically if required for the vehicle transfer </p>
        <p> 3.	The buyer has inspected the vehicle and is satisfied with its condition </p>
    </div><br />
    <div class="Finance_Details">
        <h3>Finance Details:</h3>
        <p>
            1.	Loan Amount (if applicable): ₹ <span class="blank-line"></span> 2.	Amount Paid: ₹ <span class="blank-line"></span>
            3.	Total Interest Payable and Late Payment (if applicable): ₹ <span class="blank-line"></span>
        </p>
    </div><br />
    <div class=" Sale-Consideration">

        <h3>Sale Consideration:</h3>
        <p>
            The seller has sold the vehicle to the buyer for ₹----------
        </p>
    </div>
    <br />
    <br /><br />
    <div class="last mb-3">
        <div class="column">
            <h3>Seller's Sign</h3>
            <div class="box"><img src="/assets/img/pic-1.jpg" alt="pic" /></div>
        </div>
        <br />
        <div class="column">
            <h3>Buyer's Sign</h3>
            <div class="box"><img src="/assets/img/pic-1.jpg" alt="pic" /></div>
        </div>
        <br />
        <div class="column">
            <h3>Witness Sign</h3>
            <div class="box">
                <img src="/assets/img/pic-1.jpg" alt="pic" />
            </div>
        </div>
        <br />
        <div class="column">
            <h3>Vehicle Photo</h3>
            <div class="box">
                <img src="/assets/img/bike.jpg" alt="bike" />
            </div>
        </div>
    </div>
    <!-- 3/4 wheeler -->
    <div class="last mt-3">
        <div class="column">
            <h3>Vehicle Photo</h3>
            <div class="box"><img src="/assets/img/auto-1.jpg" alt="pic" /></div>
        </div>
        <br />
        <div class="column">
            <h3>Vehicle Photo</h3>
            <div class="box"><img src="/assets/img/auto-1.jpg" alt="pic" /></div>
        </div>
        <br />
        <div class="column">
            <h3>Vehicle Photo</h3>
            <div class="box">
                <img src="/assets/img/auto-1.jpg" alt="pic" />
            </div>
        </div>
        <br />
        <div class="column">
            <h3>Vehicle Photo</h3>
            <div class="box">
                <img src="/assets/img/auto-1.jpg" alt="bike" />
            </div>
        </div>
    </div>
</div>
    


