<!--10th Form-->
<div class="ptb-100">
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center ">
            <h6 class="text-white display-3 mb-4 wow fadeInDown" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInDown;">
                Form-36
            </h6>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInDown;">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active text-white">Form-36</li>
            </ol>
        </div>
    </div>
    <br /><br /><div style="margin-top:60px"></div>
                <div id="pdf-content" class="mt-5 ">
                    <div class="container pb-0 content">

                        <div class="row header">
                            <div class="col-sm-4 col-md-4 col-4 col-lg-12 text-center right no-gap">
                                <div class="middle">
                                    <h2><b>FORM -36</b></h2>
                                    <p><b>[See rule 61 (2)]</b></p>
                                    <h6>APPLICATION FOR ISSUE OF A FRESH CERTIFICATE OF REGISTRATION IN THE NAME OF THE FINANCIER</h6>
                                    <br /><br />
                                </div>
                            </div>
                            <div class="col-sm-4 col-md-4 col-4">
                            </div>
                            <div class="col-sm-4 col-md-4 col-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div class="double-line-container"></div>
                    <div class="container-fluid pt-3 content">
                        <div class="container">
                            <div class="row no-gap">
                                <div class="col-lg-12 border-top1 border-top2   ">
                                    <br />

                                    <p>To</p>
                                </div>
                                <div class="col-12 text-start">
                                    <p style="margin-left:18px;">THE REGISTRATION AUTHORITY,</p>
                                    <p> <span style="color:red">{{Fdata[0].RegisteringAuthority}}</span></p><br />
                                </div>
                            </div><br />
                            <div class="main-content">
                                <!--<h5>Ref:<strong class="text-danger">Dynamic {{ Fdata[0].AgreementNo }}</strong></h5>-->
                                <p>
                                    I/We <span style="color:red">{{loginDet[0].CompanyName}}</span>,<span style="color:red">{{loginDet[0].CompanyAddress}}</span>(Financier) have taken possession of motor vehicle No. <span style="color:red">{{Fdata[0].VehicleRegNo}}</span>
                                    Make <span style="color:red">{{Fdata[0].Manufacture}}</span> Model <span style="color:red">{{Fdata[0].Model}}  </span>Owing to the default of the Registered Owner
                                    (name) <span style="color:red">{{Fdata[0].BorrowerName}}</span> , (Address)<span style="color:red">{{Fdata[0].BarrowerAddress}}</span>  <br /><br />
                                </p>
                                <p>Under the provisions of the agreement of hire purchase/lease/hypothecation</p>
                                <p>1) The Certificate of Registration of the said vehicle is surrendered here with</p>
                                <p>2) The Registered owner has refused to deliver the Certificate of Registration to me / us.</p>
                                <p>3) The Registered owner is absconding</p><br />
                                <p>I/We request you to cancel the Certificate and issue a fresh certificate of registration in my/our name.</p><br />
                                <p>I/We enclose a fee of Rs.<span style="color:red">{{Fdata[0].Fee_FC}}.00/-</span></p><br />
                                <br />
                                <br />
                                <br />
                            </div>
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="text-start">
                                        <p>Date: <span style="color:red">{{todayDate}}</span></p><br />
                                    </div>
                                </div>
                                <div class="col-lg-8 text-left">
                                    <div class="text-end">
                                        <p>Signature of the Financier</p><br />
                                    </div>
                                </div>
                            </div>
                            <br />
                            <br />
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="text-start">
                                        <p>Specimen Signature of the Financier</p>
                                        <p>1.________________________</p>
                                        <p>2.________________________</p><br />
                                    </div>
                                </div>
                                <div class="col-lg-12 text-left">
                                    <div class="text-start">
                                        <p>Copy to the Original registering authority.</p>
                                        <p>Strike out whenever is inapplcable.</p>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <br />
                            <br />
                        </div>
                    </div> <br />
                   
                </div>
    <br />
    <br />
    
        <div class="row">
            <div class="col-lg-8 ">
            </div>
            <div class="col-lg-1 ">
                <button (click)="navigateTOeditform(Fdata[0].RefNo)" class="pdf"><i class="fa fa-edit"></i> Edit  </button>
            </div>
            <div class="col-lg-2  ">
                <button (click)="downloadPDF()" class="pdf"><i class="fa fa-download"></i> Generate Pdf  </button>
            </div>


        </div>
    </div>
