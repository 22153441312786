<app-navbar></app-navbar>

<div class="ptb-100">
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center ">
            <h6 class="text-white display-3 mb-4 wow fadeInDown" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInDown;">
                Registration
            </h6>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInDown;">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active text-white"> Registration</li>
            </ol>
        </div>
    </div>
    <div class="container pading-5 mt-5">

        <h3>Select your Role</h3>

        <div class="row">
            <div class="col-lg-12 d-flex  flex-wrap">
                <!-- Centering and wrapping cards -->
                <div class="card" (click)="storeName('Financier')">
                    <img src="../../../assets/img/finanical.jpg" alt="financer" />
                    <button>Financier &gt;</button>
                </div>
                <div class="card" (click)="storeName('Pawn Broker')">
                    <img src="../../../assets/img/pawn.jpg" alt="pawn" />
                    <button>Pawn Broker &gt;</button>
                </div>
                <div class="card" (click)="storeName('Seizer')">
                    <img src="../../../assets/img/seizer.jpg" alt="seizer" />
                    <button>Seizer &gt;</button>
                </div>
                <div class="card" (click)="storeName('Seizer Yards')">
                    <img src="../../../assets/img/seizeryard.png" alt="seizer" />
                    <button>Seizer Yards &gt;</button>
                </div>
                <div class="card" (click)="storeName('RTA Agent')">
                    <img src="../../../assets/img/RTA.jpg" alt="rta" />
                    <button>RTA Agent &gt;</button>
                </div>
                <div class="card"(click)="storeName('Second Sale')">
                    <img src="../../../assets/img/secondsale.png" alt="rta" />
                    <button class="bt" target="_blank" rel="noopener noreferrer" >Second Sale &gt;</button>
                </div>
            </div>
        </div>

    </div>

 
</div>












<!--<div class="ptb-100">
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center ">
            <h6 class="text-white display-3 mb-4 wow fadeInDown" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInDown;">
                Registration
            </h6>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInDown;">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active text-white"> Registration</li>
            </ol>
        </div>
    </div>
    <div class="container mt-5">
        <div class="card">
            <h3>Select your Role</h3>
            <div class="container">
                <div class="row">
                    <div class="col-lg-3">
                        <div class="card">
                            <img src="../../../assets/img/finanical.jpg" class="finanse" alt="financer" />
                            <button class="bt" style="margin-top: 83px;" target="_blank" rel="noopener noreferrer" (click)="storeName('Financier')">Financier &gt;</button>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="card">
                            <img src="../../../assets/img/pawn.jpg" alt="pawn" />
                            <button class="bt" style=" margin-top: 30px;" target="_blank" rel="noopener noreferrer" (click)="storeName('Pawn Broker')">Pawn Broker &gt;</button>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="card">
                            <img src="../../../assets/img/seizer.jpg" alt="seizer" />
                            <button class="bt" target="_blank" rel="noopener noreferrer" (click)="storeName('Seizer')">Seizer &gt;</button>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="card">
                            <img src="../../../assets/img/seizeryard.png" alt="seizer" />
                            <button class="bt" target="_blank" rel="noopener noreferrer" (click)="storeName('Seizer Yards')">Seizer Yards&gt;</button>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="card">
                            <img src="../../../assets/img/RTA.jpg" alt="rta" />
                            <button class="bt" target="_blank" rel="noopener noreferrer" (click)="storeName('RTA Agent')">RTA Agent &gt;</button>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="card">
                            <img src="../../../assets/img/secondsale.png" alt="rta" />
                            <button class="bt" target="_blank" rel="noopener noreferrer" (click)="storeName('Second Sale')">Second Sale &gt;</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>-->

