import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { GeneralserviceService } from '../../generalservice.service';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-noticeform4',
 
  templateUrl: './noticeform4.component.html',
  styleUrl: './noticeform4.component.scss'
})
export class Noticeform4Component {

    HomeUrl: string;
    arr: any[] = [];
    loginDet: any;
    otpSent: boolean = false;
    showLogin: boolean = false;
    showLogin1: boolean = true;
    registerAsDonor: boolean = false;
    RegId: any;
    LoginDetails: any;
    RegisterForm: FormGroup;
    OTPtoMobile: string;
    checkNo: number;
    Mobilenum: string;
    mobiledata: any;
    classdata: any;
    classname: any;
    deptList: any;
    deptList1: any;
    // State: any;
    StateId: any;
    DistrictName: any;
    flag: any;
    rolename: any
    Districtdata: any;
    selectedDistrictName: any;
    Statedata: any;
    State: any = [];
    selectedStateName: any;
    selectedstateid: any;
    district: any = [];
    Citydata: any = [];
    Fdata: any;
    aggrementNo: any
    isUpdateMode: boolean = false;
    LegalNoticeID: number;
    cardNumber: any;
    Sname: any;
    refno: any;
    no4: any;
    final4: any;
    constructor(
        public router: Router,
        private formBuilder: FormBuilder,
        public generalService: GeneralserviceService,
        public http: HttpClient,
        public activeroute: ActivatedRoute
    ) {
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
     
        this.final4 = localStorage.getItem("final4");
       

        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
        debugger
        // this.LegalNoticeID = this.cardNumber;
        this.initializeForm();

        if (!this.loginDet || !this.loginDet[0] || !this.loginDet[0].RegID) {
            this.router.navigate(['/Login']);
            return;
        }
    }


    initializeForm(): void {


        this.RegisterForm = this.formBuilder.group({
            RefNo: ['', Validators.required],
            RTAName: ['', Validators.required],
            RTAAddress: ['', Validators.required],
            BorrowerName: ['', Validators.required],
            BarrowerAddress: ['', Validators.required],
            BarrowerMobile: ['', Validators.required],
            FatherName: ['', Validators.required],
           // VehicleBearingNumber: ['', Validators.required],
            //VehicleBearingNumber: ['', Validators.required],
            VehicleRegNo: ['', Validators.required],
            SuretyAddress: ['', Validators.required],
            SuretyName: ['', Validators.required],
            wheeltype: ['', Validators.required],
            //VehicleRegNo: ['', Validators.required]
           
        });

    }


    ngOnInit() {
      

        this.Getforms();
        this.loadEditData();
    }
    loadEditData(): void {
        const wheeltype = localStorage.getItem('wheeltype');
        if (wheeltype) {
            this.RegisterForm.patchValue({ wheeltype });
        }
    }
    convertToUppercase() {
        const control = this.RegisterForm.get('VehicleRegNo');
        if (control) {
            control.setValue(control.value.toUpperCase(), { emitEvent: false });
        }
    }

    Getforms() {
        debugger;
        const uploadfile = new FormData();
        uploadfile.append('Param', this.final4);
        var url = "api/FinancierLaw/FA_NoticeForms_basedon_ARGNO";
        this.generalService.Postdata1(url, uploadfile).subscribe(
            data => {
                debugger;
                this.Fdata = data;
                //const auctionedDate = this.formatDate(this.Fdata[0].AuctionedDate);
                this.RegisterForm.patchValue({

                    RTAName: this.Fdata[0].RTAName,
                    RTAAddress: this.Fdata[0].RTAAddress,
                    BorrowerName: this.Fdata[0].BorrowerName,
                    BarrowerAddress: this.Fdata[0].BarrowerAddress,
                    BarrowerMobile: this.Fdata[0].BarrowerMobile,
                    FatherName: this.Fdata[0].FatherName,
                //    VehicleBearingNumber: this.Fdata[0].VehicleBearingNumber,
                    VehicleRegNo: this.Fdata[0].VehicleRegNo,
                    SuretyAddress: this.Fdata[0].SuretyAddress,
                    SuretyName: this.Fdata[0].SuretyName



                    // Add other fields as needed based on Fdata
                });
            },
            err => {
                //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
            }
        );
    }
    formatDate(dateString: string): string {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    }

    OnSubmit() {
        throw new Error('Method not implemented.');
    }

    reg() {
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            this.router.navigate(['/Login']);
            return;
        } else {
            this.router.navigate(['/Home']).then(() => window.location.reload());
        }
    }




    getRegistration(value: any) {
        debugger;

        //if (!value.BorrowerName || !value.BarrowerAddress || !value.FatherName ||
        //     !value.VehicleRegNo) {
        //    alert("Please fill all the mandatory fields: Borrower Name, Barrower Address, Father Name, Vehicle Reg Number.");
        //    return; // Exit the function if validation fails
        //}
        this.arr = [];
        const wheeltype = this.RegisterForm.get('wheeltype')?.value;
        var arr = [{
            // Name: value.Name,
 
            NoticeID: 5,

            RefNo: value.RefNo,
            RTAName: value.RTAName,
            RTAAddress: value.RTAAddress,
            BorrowerName: value.BorrowerName,
            BarrowerAddress: value.BarrowerAddress,
            FatherName: value.FatherName,
         //   VehicleBearingNumber: value.VehicleBearingNumber,
            VehicleRegNo: value.VehicleRegNo,
            SuretyAddress: value.SuretyAddress,
            BarrowerMobile: value.BarrowerMobile,
            SuretyName: value.SuretyName,

            RegID: this.loginDet[0].RegID,
            LoanAmount: 0.00,
            DueAmount: 0.00,
            AuctionReceivedMoney: 0.00,
            TotalAmount: 0.00,
            MonthlyIncome: 0.00
        }];
        localStorage.setItem('wheeltype', wheeltype);
        const uploadfile = new FormData();
        uploadfile.append('Param', JSON.stringify(arr));
        uploadfile.append('Flag', '1');

        const url = 'api/FinancierLaw/FA_NoticeFormsCRUD';
        this.generalService.Postdata1(url, uploadfile).subscribe((data: any) => {
            debugger
            this.refno = data
          
            if (data && data) {

                alert("Your Form submitted successfully");
                localStorage.setItem('objection', data);
                this.router.navigate(['/finalnotice4']);


            }
        },

        );
    }
    updateRegistration(value: any) {
        debugger;
        this.arr = [];
        const wheeltype = this.RegisterForm.get('wheeltype')?.value;
        var arr = [{
            NoticeFormID: this.Fdata[0].NoticeFormID,
            NoticeID: 5,           
            RTAName: value.RTAName,
            RTAAddress: value.RTAAddress,
            BorrowerName: value.BorrowerName,
            BarrowerAddress: value.BarrowerAddress,
            FatherName: value.FatherName,
            SuretyAddress: value.SuretyAddress,
          //  VehicleBearingNumber: value.VehicleBearingNumber,
            VehicleRegNo: value.VehicleRegNo,
            BarrowerMobile: value.BarrowerMobile,
            SuretyName: value.SuretyName,

            RegID: this.loginDet[0].RegID,
            LoanAmount: 0.00,
            DueAmount: 0.00,
            AuctionReceivedMoney: 0.00,
            TotalAmount: 0.00,
            MonthlyIncome: 0.00
        }];
        localStorage.setItem('wheeltype', wheeltype);
        const uploadfile = new FormData();
        uploadfile.append('Param', JSON.stringify(arr));
        uploadfile.append('Flag', '2');

        const url = 'api/FinancierLaw/FA_NoticeFormsCRUD';
        this.generalService.Postdata1(url, uploadfile).subscribe((data: any) => {
            debugger

            if (data && data) {
                debugger
                alert("Your Form Updated successfully");
                localStorage.setItem('objection', data);
                this.router.navigate(['/finalnotice4']);
                
                localStorage.removeItem('final4');
            }

        },

        );
    }

  

    




}
