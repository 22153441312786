
<div class="pt-100 bgblue">
    <br />
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center ">
            <h6 class="text-white display-3 mb-4 wow fadeInDown" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInDown;">
                Black List
            </h6>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInDown;">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active text-white"> Black List</li>
            </ol>
        </div>
    </div>
    <div class="container mt-5 box-container">
        <div class="col-lg-12">
            <div class="row">
                <div class="col-lg-3">
   
                </div><div class="col-lg-3"></div>
                      <div class="col-lg-6">

                          <div class="input-wrapper">
                              <i class="fa fa-search search-icon"></i> <!-- Search icon at the start -->

                              <input type="text" class="searchh" placeholder="Search by Name/Address/BlockedBy"
                                     [(ngModel)]="nameFilter" (input)="applyFilter()" style="width:100%;" />

                              <div *ngIf="nameFilter"
                                   class="close-btn"
                                   (click)="clearSearch()">X</div>
                          </div>

                      </div>
                
            </div>

            <div class="box-content" style="overflow-x: auto;">
              
                    <table class="table-hover" border="1" cellpadding="10" cellspacing="0" style="width: 100%; min-width: 700px;">
                        <thead>
                            <tr>
                                <!--<th class="custom_border">Action</th>-->
                                <th class="custom_border">Name</th>
                                <th class="custom_border">Address</th>
                                <th class="custom_border">Father</th>
                                <th class="custom_border">Age</th>
                                <th class="custom_border">Adharno</th>
                                <th class="custom_border">Drivinglicense</th>
                              
                                <th class="custom_border">BlockedBy</th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr *ngFor="let item of BlacklistData">
                                <!--<td>
        <i *ngIf="item.FinancerName === LoginDetails[0]?.Name"
           class="fas fa-trash"
           (click)="deleteItem1(item.BLid)"
           style="color: red; cursor: pointer;">
        </i>
    </td>-->
                                <td>{{item.Name}}</td>
                                <td>{{item.Address}}</td>
                                <td>{{item.Father}}</td>
                                <td>{{item.Age}}</td>
                                <td>{{item.FinancerName}}</td>
                                <td>{{formatAadhar(item.Adharno)}}</td>
                                <td>{{item.FinancerName}}</td>
                            </tr>
                        </tbody>

                    </table>
                    <div class="pagination-controls">
                        <button class="pagi" (click)="previouesBlack()"><<<</button>
                        <span> {{currentPage}} </span>
                        <button class="pagi" (click)="nextBlack()">>>></button>
                    </div>
               
            </div>
        </div>
    </div>
</div>
