<div class="container pt-5 mt-5">
    <div class="topheader">
        <h1 style="font-size: 27px; font-family: airal black; text-decoration: none;">SR AUTO CONSULTANTS & COMMISSION AGENT</h1>
        <p style="text-align:center; line-height: 1.2rem"> #49, kushal Tower, M.Piller No.A-1188,<br /> Khairtabad, Hyderabad,<br />Pin No: 500004.<br />9871658973</p>
    </div><br />
    <div class="header">
        <h1 style="text-align:center;">VEHICLE SALE AGREEMENT</h1>
        <!--<p style="text-align:center"> #49, kushal Tower, M.Piller No.A-1188,<br /> Khairtabad, Hyderabad-500 004</p>-->
        <!--<p style="float:right">Date: <span class="blank-line"></span></p>--> <!--<p style="float:right">Date: <span class="blank-line"></span></p>-->
        <p class="float-end">Date:----</p><br />

    </div>
    <div class="buyer-details">
        <h3>Sellar Details:</h3>
        <p>
            Name:  <span class="blank-line"></span>,
            Father's Name:<span class="blank-line"></span>, Age:  <span class="blank-line"></span>,
            Occupation:  <span class="blank-line"></span>,
            Address:  
            H. No <span class="blank-line"></span>Street No <span class="blank-line"></span> Colony Name:<span class="blank-line"></span> Mandal<span class="blank-line"></span> State<span class="blank-line"></span> Pin code:<span class="blank-line"></span>

        </p>
    </div><br />
    <div class="seller-details:">
        <h3>Buyer Details:</h3>
        <p>
            Name: ------------,
            Father's Name: -----------,Age: ---------,
            Occupation:  ______________
            Address: <span class="blank-line"></span>,
            H. No <span class="blank-line"></span>, Street No<span class="blank-line"></span>, Colony Name:<span class="blank-line"></span>, Mandal <span class="blank-line"></span>, State <span class="blank-line"></span>, Pin code: <span class="blank-line"></span>

        </p>
    </div>
    <br />
    <div class="vehicle-details">
        <h3>Vehicle Details:Transport / Non Transport:</h3>
        <p>
            Registration Number: <span class="blank-line"></span>,   Chassis   Number:  <span class="blank-line"></span>,   Engine
            Number: <span class="blank-line"></span>, Vehicle Type: [Car/Bike/Auto/Other]	 <span class="blank-line"></span>
            MODEL <span class="blank-line"></span>FUEL <span class="blank-line"></span> PETROL/DIESEL/LPG/CNG/EV/OTHER ----------,RC Validity:---------,FITNESS VALIDITY:_____________, PERMIT
            Number___________ VALIDITY:________ , METER SEAL VALIDITY:___________, CNG/LPG
            TANK VALIDITY _ _ _ _ _ __ , E.V. BATTERY WARRANTY _ _ _ _ _ , TAX
            VALIDITY<span class="blank-line"></span>
        </p>
    </div>
    <br />
    <div class="sale-terms">
        <h3>Sale Terms:</h3>
        <p>
            1.The Seller agrees to sell the vehicle to the Buyer for ₹-----.<br />
            2.The Buyer acknowledges that the vehicle is being sold in its current condition.<br />
            3.The Seller warrants that the vehicle is free from any finance or loan obligations/with finanace.<br />
            4.The Seller warrants that the vehicle is free from any RTA, police, traffic costs, or civil and criminal cases.<br />
            5.Vehicles once sold will not be taken back or exchanged and papers validation must be done in 30 days<br />
            6.No guarantee on sale of old vehicle parts and repairs<br />
            7.Transfer of ownership should be done by the purchaser itself within 30 days<br />
        </p>
    </div><br />
    <div class="Payment-terms">
        <h3> Payment-terms:</h3>
        <p>
            1.The Buyer shall pay the sale price in full by Payment Method, e.g., cash, bank transfer / With Finance<br />
            2.The payment shall be made on or before (Date------) <br />
        </p>
        <h3>Finance Details: </h3>
        1. Total Cost of Vehicle Rs _________.
        , 2. Amount Paid by Buyer Rs____________ Rs, 3.Remaining amount financied________
        , 4. Name of the Financier____________
        , 5.Address____________
    </div>
    <br />
    <div class="Payment details" >
        <h3>Payment details:</h3>
        <p>
            Cash Received Advanced Rs_____Due amount  Rs_________Pending  Due date____________
        </p><br />
        <!--<span style="text-align:end;position:absolute;right:20px;">P.T.O</span><br />-->
    </div>
    <div class="buyer-details-Signature mt-3" style="position: relative">
        <h3>
            BuyerSignature:
        </h3>
        <span style="text-align:end;position:absolute;right:20px;">P.T.O</span><br />
    </div>
        <p>-------------------------------------------------------------------------</p>
        <div class="Delivery-Note">
            <h3 style="text-align:center;">PART-<span style=" font-weight: 500; font-family: 'Poppins';"><b>&#8545;</b></span> DeliveryNote</h3>
        </div>
        <div class="buyer-details-Signature">         

            <div class=" TRANSFER-OF-OWNERSHIP:">

                <h3>TRANSFER OF OWNERSHIP:</h3>
                <p>
                    1.The Seller shall transfer the ownership of the vehicle to the Buyer upon receipt of the sale price.
                    <br /> 2.The Buyer shall be responsible for registering the vehicle in their name
                </p>
            </div>
            <br />
            <div class="WARRANTIES-AND-REPRESENTATIONS:">

                <h3>WARRANTIES AND REPRESENTATIONS:</h3>
                <p>
                    1.The Seller warrants that the vehicle is free from any defects or liabilities.
                    <br />  2.The Buyer acknowledges that they have inspected the vehicle and are satisfied with its present condition.
                </p>
            </div>
            <br />
            <div class="DISPUTE-RESOLUTION:">

                <h3>DISPUTE RESOLUTION:</h3>
                <p>Once vehicle is sold there will be no more opportunities for any disputes</p>

            </div>
            <br />
            <div class="ACKNOWLEDGMENT:">

                <h3>ACKNOWLEDGMENT:</h3>
                <p>
                    By signing below, both parties acknowledge that they have read, understand, and agree to the terms and conditions of this Agreement and buyer has received the vehicle.
                </p>
            </div>
            <br />

            <div class="last">
                <div class="column">
                    <h3>Seller's Sign</h3>
                    <div class="box"><img src="/assets/img/pic-1.jpg" alt="pic" /></div>
                </div>
                <br />
                <div class="column">
                    <h3>Buyer's Sign</h3>
                    <div class="box"><img src="/assets/img/pic-1.jpg" alt="pic" /></div>
                </div>
                <br />
                <div class="column">
                    <h3>Witness Sign</h3>
                    <div class="box">
                        <img src="/assets/img/pic-1.jpg" alt="pic" />
                    </div>
                </div>
                <br />
                <div class="column">
                    <h3>Vehicle Photo</h3>
                    <div class="box">
                        <img src="/assets/img/bike.jpg" alt="bike" />
                    </div>
                </div>
            </div>
        </div>
        <br />
        <div class="last mt-3">
            <div class="column">
                <h3>Vehicle Photo</h3>
                <div class="box"><img src="/assets/img/car-1.jpg" alt="pic" /></div>
            </div>
            <br />
            <div class="column">
                <h3>Vehicle Photo</h3>
                <div class="box"><img src="/assets/img/car-1.jpg" alt="pic" /></div>
            </div>
            <br />
            <div class="column">
                <h3>Vehicle Photo</h3>
                <div class="box">
                    <img src="/assets/img/car-1.jpg" alt="pic" />
                </div>
            </div>
            <br />
            <div class="column">
                <h3>Vehicle Photo</h3>
                <div class="box">
                    <img src="/assets/img/car-1.jpg" alt="bike" />
                </div>
            </div>
        </div>

        <p> <b>Final Receipt:</b> Total Amount of Rs-------------	Received From Seller Signature</p>


    </div>


