import { BrowserModule } from '@angular/platform-browser';
import { NgModule, isDevMode } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CountUpModule } from 'ngx-countup';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { CommonModule } from '@angular/common'; // Import CommonModule
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { HomeOneComponent } from './components/home-one/home-one.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { WelcomeComponent } from './components/common/welcome/welcome.component';
import { AboutComponent } from './components/common/about/about.component';
import { StrategyComponent } from './components/common/strategy/strategy.component';
import { WhoWeAreComponent } from './components/common/who-we-are/who-we-are.component';
import { CtaComponent } from './components/common/cta/cta.component';
import { SkillComponent } from './components/common/skill/skill.component';
import { FunfactsComponent } from './components/common/funfacts/funfacts.component';
import { TeamComponent } from './components/common/team/team.component';
import { HowWeWorkComponent } from './components/common/how-we-work/how-we-work.component';
import { ServicesComponent } from './components/common/services/services.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { SubscribeComponent } from './components/common/subscribe/subscribe.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { BlogComponent } from './components/common/blog/blog.component';
import { FeedbackComponent } from './components/common/feedback/feedback.component';
import { FaqComponent } from './components/common/faq/faq.component';
import { PricingComponent } from './components/common/pricing/pricing.component';
import { WorkComponent } from './components/common/work/work.component';
import { WhyWeDifferentComponent } from './components/common/why-we-different/why-we-different.component';
import { ContactComponent } from './components/common/contact/contact.component';
import { HomeTwoComponent } from './components/home-two/home-two.component';
import { HomeThreeComponent } from './components/home-three/home-three.component';
import { HomeFourComponent } from './components/home-four/home-four.component';
import { HomeFiveComponent } from './components/home-five/home-five.component';
import { ServicesTwoComponent } from './components/common/services-two/services-two.component';
import { HomeSixComponent } from './components/home-six/home-six.component';
import { HomeSevenComponent } from './components/home-seven/home-seven.component';
import { HomeEightComponent } from './components/home-eight/home-eight.component';
import { HomeNineComponent } from './components/home-nine/home-nine.component';
import { HomeTenComponent } from './components/home-ten/home-ten.component';
import { HomeElevenComponent } from './components/home-eleven/home-eleven.component';
import { HomeTwelveComponent } from './components/home-twelve/home-twelve.component';
import { HomeThirteenComponent } from './components/home-thirteen/home-thirteen.component';
import { BlogDetailsComponent } from './components/blog-details/blog-details.component';
import { DemoSidebarComponent } from './components/common/demo-sidebar/demo-sidebar.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HowitisworksComponent } from 'src/app/Pages/howitisworks/howitisworks.component';
import { AboutUsComponent } from 'src/app/Pages/about-us/about-us.component';
import { ContactUsComponent } from 'src/app/Pages/contact-us/contact-us.component';
import { HomeComponent } from 'src/app/Pages/home/home.component';
import { RegistrationComponent } from 'src/app/Pages/registration/registration.component';
import { LoginComponent } from 'src/app/Pages/login/login.component';
import { SubcriptionComponent } from 'src/app/Pages/subcription/subcription.component'
import { HttpClientModule } from '@angular/common/http';
import { RoleSelectionComponent } from 'src/app/Pages/role-selection/role-selection.component';
import { NoticeformComponent } from 'src/app/Pages/noticeform/noticeform.component';
import { PreviewComponent } from 'src/app/Pages/preview/preview.component';
import { FinacialformPDFComponent } from './components/finacialform-pdf/finacialform-pdf.component';
import { PawanbrokerPDFComponent } from './components/pawanbroker-pdf/pawanbroker-pdf.component';
import { HomepageComponent } from 'src/app/Pages/homepage/homepage.component';
import { FinalnoticeComponent } from './Pages/finalnotice/finalnotice.component';
import { Noticeform1Component } from './Pages/noticeform1/noticeform1.component'
import { Noticeform2Component } from './Pages/noticeform2/noticeform2.component';
import { Finalnotice2Component } from './Pages/finalnotice2/finalnotice2.component';
import { Noticeform3Component } from './Pages/noticeform3/noticeform3.component';
import { Finalnotice3Component } from './Pages/finalnotice3/finalnotice3.component';
import { Noticeform4Component } from './Pages/noticeform4/noticeform4.component';
import { Finalnotice4Component } from './Pages/finalnotice4/finalnotice4.component';
import { Noticeform5Component } from './Pages/noticeform5/noticeform5.component';
import { Finalnotice5Component } from './Pages/finalnotice5/finalnotice5.component';
import { Noticeform6Component } from './Pages/noticeform6/noticeform6.component';
import { Finalnotice6Component } from './Pages/finalnotice6/finalnotice6.component';
import { Noticeform7Component } from './Pages/noticeform7/noticeform7.component';
import { Finalnotice7Component } from './Pages/finalnotice7/finalnotice7.component';
import { Form35Component } from './Pages/form35/form35.component';
import { Final35Component } from './Pages/final35/final35.component';
import { Form36Component } from './Pages/form36/form36.component'
import { Final36Component } from './Pages/final36/final36.component'
import { SearchrefrenceComponent } from './Pages/searchrefrence/searchrefrence.component';
import { AbscondingformComponent } from './Pages/abscondingform/abscondingform.component';
import { BlacklistComponent } from './Pages/blacklist/blacklist.component'
import { BuyerComponent } from './buyer/buyer.component';
import { SellerComponent } from './seller/seller.component';
import { AgreementformComponent } from './Pages/agreementform/agreementform.component'
import { SurrenderpdfComponent } from './Pages/surrenderpdf/surrenderpdf.component'
//import { SellerformComponent } from './Pages/sellerform/sellerform.component';
import { SellformComponent } from './Pages/sellform/sellform.component'
import { Sell1formComponent } from './Pages/sell1form/sell1form.component'
//import { LegalnoticeComponent } from './Pages/legalnotice/legalnotice.component';
import { LegalformComponent } from './Pages/legalform/legalform.component';
import { AbscondinglistComponent } from './Pages/abscondinglist/abscondinglist.component'
import { BuyformComponent } from './Pages/buyform/buyform.component';
import { BuyerformComponent } from './Pages/buyerform/buyerform.component'
import { MyprofileComponent } from './Pages/myprofile/myprofile.component'
import { BlocklistComponent } from './Pages/blocklist/blocklist.component'
import { AgreementpdfComponent } from './Pages/agreementpdf/agreementpdf.component'
import { SurrenderformComponent } from './Pages/surrenderform/surrenderform.component'
import { LoanrescheduleComponent } from './Pages/loanreschedule/loanreschedule.component'
import { LoanformComponent } from './Pages/loanform/loanform.component'
import { RecoverymanagerComponent } from './Pages/recoverymanager/recoverymanager.component'
import { PawndocumentformComponent } from './Pages/pawndocumentform/pawndocumentform.component'
import { RecoverymanagerformComponent } from './Pages/recoverymanagerform/recoverymanagerform.component'
@NgModule({
    declarations: [
        AppComponent, NoticeformComponent, FinacialformPDFComponent, PawanbrokerPDFComponent, Noticeform2Component,  PreloaderComponent,
        HomeOneComponent,
        NavbarComponent, Finalnotice2Component, Noticeform3Component, Finalnotice3Component, Noticeform4Component, Finalnotice4Component,
        WelcomeComponent, Noticeform5Component, Finalnotice5Component, Noticeform6Component, Finalnotice6Component, Noticeform7Component,
        AboutComponent, Finalnotice7Component, Form35Component, Final35Component, Form36Component, Final36Component,
        SearchrefrenceComponent, StrategyComponent, LegalformComponent,//LegalnoticeComponent,
        WhoWeAreComponent, BuyerformComponent, //SellerformComponent,
        CtaComponent, AbscondingformComponent, BlacklistComponent, SellformComponent, Sell1formComponent,
        SkillComponent, BuyformComponent, MyprofileComponent, SurrenderformComponent,
        FunfactsComponent, AbscondinglistComponent, BlocklistComponent,
        TeamComponent, AgreementformComponent, LoanrescheduleComponent,
        HowWeWorkComponent, AgreementpdfComponent, LoanformComponent,
        ServicesComponent, SurrenderpdfComponent,
        FooterComponent,
        SubscribeComponent,
        PartnerComponent,
        BlogComponent,
        FeedbackComponent,
        FaqComponent,
        PricingComponent,
        WorkComponent,
        WhyWeDifferentComponent,
        ContactComponent,
        HomeTwoComponent,
        HomeThreeComponent,
        HomeFourComponent,
        HomeFiveComponent,
        ServicesTwoComponent,
        HomeSixComponent,
        HomeSevenComponent,
        HomeEightComponent,
        HomeNineComponent,
        HomeTenComponent,
        HomeElevenComponent,
        HomeTwelveComponent, 
        HomeThirteenComponent,
        BlogDetailsComponent,
        DemoSidebarComponent, Noticeform1Component,
        RegistrationComponent, HomeComponent, LoginComponent, AboutUsComponent,
        ContactUsComponent, SubcriptionComponent, RoleSelectionComponent, PreviewComponent, HomepageComponent,
        FinalnoticeComponent, BuyerComponent,
        SellerComponent, RecoverymanagerComponent, PawndocumentformComponent, RecoverymanagerformComponent
        

    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        NgxScrollTopModule,
        CarouselModule,
        FormsModule, ReactiveFormsModule ,
        CountUpModule, NgxTypedJsModule,
        HowitisworksComponent,
        CommonModule ,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: !isDevMode(),
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        })
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }

