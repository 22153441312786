<div class="ptb-100">
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center ">
            <h6 class="text-white display-3 mb-4 wow fadeInDown" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInDown;">
                My Profile
            </h6>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInDown;">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active text-white">  My Profile</li>
            </ol>
        </div>
    </div>


    <div class="container mt-5">
        <div class="row">
            <div class="col-12">
                <fieldset>
                    <legend>
                        <p class="legend">&nbsp; My Profile</p>
                    </legend>

                    <form [formGroup]="RegisterForm">
                        <div class="row p-5">
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="Name">Full Name*</label>
                                    <input readonly type="text" formControlName="Name" placeholder="Enter Your Name" id="first-name" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="Mobile">Mobile Number*</label>
                                    <input readonly type="text" formControlName="Mobile" placeholder="Enter Your Mobile Number" readonly inputmode="numeric" pattern="[0-9]*" (input)="CheckMobileNo()" maxlength="10" required>
                                    <small class="text-danger" *ngIf="RegisterForm.get('Mobile').touched">
                                        <span *ngIf="RegisterForm.get('Mobile').hasError('required')">Mobile is required</span>
                                        <span *ngIf="checkNo==10">Mobile Number Exist</span>
                                    </small>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="EMail">Email*</label>
                                    <input readonly type="email" formControlName="EMail" placeholder="Enter Your Email" (input)="CheckEmail()" id="email" required>
                                    <small class="text-danger" *ngIf="RegisterForm.get('EMail').touched">
                                        <span *ngIf="RegisterForm.get('EMail').hasError('required')">Email is required</span>
                                        <span *ngIf="RegisterForm.get('EMail').hasError('email')">Invalid email format</span>
                                        <span *ngIf="checkemail==12">Email Exist</span>
                                    </small>
                                </div>
                            </div>
                            <!--<div class="col-lg-4">
        <div class="form-group">
            <label for="Password">Password*</label>
            <input type="password" formControlName="Password" placeholder="Enter Your Password" id="password" required>
        </div>
    </div>-->

                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="CompanyName">Company Name*</label>
                                    <input readonly type="text" formControlName="CompanyName" placeholder="Enter Your Company Name" id="company-name" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="CompanyAddress">Company Address</label>
                                    <input readonly type="text" formControlName="CompanyAddress" placeholder="Enter Your Company Address" id="company-address" required>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label> State Name </label>
                                    <select class="form-control" formControlName="StateName" (change)="onStateSelection($event.target.value)">
                                        <option value="">Select State name</option>
                                        <option *ngFor="let statename of Statedata" [value]="statename.StateId">{{ statename.StateName }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label> District Name </label>
                                    <select class="form-control" formControlName="DistrictName" (change)="onDistrictSelection($event.target.value)">
                                        <option value="">Select District Name</option>
                                        <option *ngFor="let districtname of DistrictName" [value]="districtname.DistrictID">{{ districtname.DistrictName }}</option>
                                    </select>
                                </div>
                            </div>


                            <!--Updated columns2-->
                            <div class="col-lg-4" *ngIf="loginDetails[0]?.RoleID === 9">
                                <div class="form-group">
                                    <label for="Name">Father Name</label>
                                    <input readonly type="text" formControlName="FatherName" placeholder="Enter Your FatherName" id="first-name" required>
                                </div>
                            </div>
                            <!--Updated columns3-->
                            <div class="col-lg-4" *ngIf="loginDetails[0]?.RoleID === 9">
                                <div class="form-group">
                                    <label for="Name">Age</label>
                                    <input readonly type="text" formControlName="Age" placeholder="Enter Your Age" id="first-name" required>
                                </div>
                            </div>
                            <!--Updated columns4-->
                            <div class="col-lg-4" *ngIf="loginDetails[0]?.RoleID === 9">
                                <div class="form-group">
                                    <label for="Name">HNo</label>
                                    <input readonly type="text" formControlName="HNo" placeholder="Enter Your HNo" id="first-name" required>
                                </div>
                            </div>
                            <!--Updated columns5-->
                            <div class="col-lg-4">
                                <div class="form-group" *ngIf="loginDetails[0]?.RoleID === 9">
                                    <label for="Name">Street</label>
                                    <input readonly type="text" formControlName="Street" placeholder="Enter Your Street" id="first-name" required>
                                </div>
                            </div>
                            <!--Updated columns6-->
                            <div class="col-lg-4">
                                <div class="form-group" *ngIf="loginDetails[0]?.RoleID === 9">
                                    <label for="Name">Colony</label>
                                    <input readonly type="text" formControlName="Colony" placeholder="Enter Your Colony" id="first-name" required>
                                </div>
                            </div>
                            <!--Updated columns7-->
                            <div class="col-lg-4" *ngIf="loginDetails[0]?.RoleID === 9">
                                <div class="form-group">
                                    <label for="Name">Mandal</label>
                                    <input readonly type="text" formControlName="Mandal" placeholder="Enter Your Mandal" id="first-name" required>
                                </div>
                            </div>
                            <!--Updated columns8-->
                            <div class="col-lg-4" *ngIf="loginDetails[0]?.RoleID === 9">
                                <div class="form-group">
                                    <label for="Name">Pincode</label>
                                    <input readonly type="text" formControlName="Pincode" placeholder="Enter Your Pincode" id="first-name" required>
                                </div>
                            </div>

                            <!--Updated columns12-->
                            <div class="col-lg-4" *ngIf="loginDetails[0]?.RoleID === 9">
                                <div class="form-group">
                                    <label for="Name">City</label>
                                    <input readonly type="text" formControlName="City" placeholder="Enter Your City" id="first-name" required>
                                </div>
                            </div>
                            <!--Updated columns13-->
                            <div class="col-lg-4" *ngIf="loginDetails[0]?.RoleID === 9">
                                <div class="form-group">
                                    <label for="Name">Occupation</label>
                                    <input readonly type="text" formControlName="Occupation" placeholder="Enter Your Occupation" id="first-name" required>
                                </div>
                            </div>
                            <!--Updated columns14-->
                            <div class="col-lg-4" *ngIf="loginDetails[0]?.RoleID === 9">
                                <div class="form-group">
                                    <label for="Name">Designation</label>
                                    <input readonly type="text" formControlName="Designation" placeholder="Enter Your Designation" id="first-name" required>
                                </div>
                            </div>

                            <div class="col-lg-4"> </div>
                            <div class="col-12">
                                <!--<div class="row float-start">
            <div class="col-lg-1 terms_1">
                <input type="checkbox" formControlName="eligibilityCriteria" style="display:flex;float:right;margin-left:8px; " (change)="checkFormValidity()">
            </div>
            <div class="col-lg-11 terms">
                <div style="cursor:pointer;" (click)="openC()">Terms and Conditions</div>
            </div>
        </div>-->
                            </div>
                            <div class="col-lg-2">
                                <div class="form-group button1">

                                    <button class="btn" (click)="getRegistration(RegisterForm.value)">
                                        <b>Update</b>
                                    </button>
                                </div>
                            </div>
                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <button class="btn" (click)="cancel()">
                                                <b>Cancel</b>
                                            </button>
                                        </div>
                                        </div>
                                   
                                </div>
</form>
                </fieldset>

            </div>

        </div>

    </div>
    <div class="modal" [ngClass]="{'show d-block': isModalOpen, 'd-none': !isModalOpen}" tabindex="-1" role="dialog" aria-labelledby="myModal22Label" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header state modal-primary">
                    <h4 style="text-align:left;color:white" class="modal-title" id="modal-primary-label">
                        Terms and Conditions
                    </h4>
                    <button type="button" class="close ml-auto" (click)="closeModal()" aria-label="Close">
                        <span aria-hidden="true" style="color:red">X</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="card_1">
                            <div class="row">
                                <div class="col-lg-12">


                                    <p>For the purposes of these Terms and Conditions:</p>
                                    <ul>
                                        <li>
                                            <p>
                                                Users of financier law app can use the documents
                                                appearing by in the app.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                No changes or additional modifications should be made in
                                                the document.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                In legal notice concerned advocate mentioned are liable
                                                only till the notice is issued.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Advocate, Financiers Legal App is not responsible for any
                                                consequences that is incurred after the notice is issued.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Financiers Legal App will assist the user only till the notice is
                                                issued. For the subsequent amounts, the user may contact the
                                                respective advocate and take legal assistance bearing the fees etc,
                                                in which the Finance Law App Management shall not interfere and
                                                shall not be liable in any way.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                It should be noted that the facilities provided to the user in
                                                Financiers Law Up are unlimited i.e. only till further agreement is
                                                made.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                If the user blacklists anyone in Financier law app, the user
                                                shall be fully responsible for legal matters.
                                            </p>
                                        </li>
                                        <li>
                                            <p>Account maintained by Users shall be kept confidential.</p>
                                        </li>
                                        <li>
                                            <p>
                                                The user published content such as IC, absconded
                                                vehicles/person as well as blacklisted ones will be visible to all
                                                users of Financier Law App and rest all the data will be
                                                confidential.
                                            </p>
                                        </li>
                                    </ul>
                                    <h2>Contact Us</h2>
                                    <p>If you have any questions about these Terms and Conditions, You can contact us:</p>
                                    <ul>
                                        <li>
                                            <p>By email: financeradvise2024&#64;gmail.com</p>
                                        </li>
                                        <li>
                                            <p>By phone number: 9381417022</p>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<style>
    .card1 ul, li {
        color: black !important;
        list-style-type: disc;
    }

    .modal-content {
        padding: 0% 3%;
        width: 160%;
        margin: 0% 0% 0% -28%;
    }

    .modal button {
        border: none;
        background-color: transparent;
        font-weight: 700;
    }

    .form-group {
        margin: 0% 0% 8%;
    }

    .terms {
        cursor: pointer;
        margin-left: -1rem;
        margin-top: -0.3rem;
    }

    .terms_1 {
        margin-left: 5px;
    }

    .button1 {
        /*margin: 0% 0% 0% !important;*/
    }
</style>
