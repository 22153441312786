<div class="ptb-100">
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center "  >
            <h6 class="text-white display-3 mb-4 wow fadeInDown" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInDown;">
                About Us
            </h6>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInDown;">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active text-white">About</li>
            </ol>
        </div>
    </div>
    <div class="container mt-5">
        <div class="row">
            <div class="col-lg-6">

                <img src="../../../assets/img/fleagal.jpeg" alt="fleagal" class="img">
            </div>
            <div class="col-lg-6 listt">
                <h4 class="sub-title pe-3 mb-3">About Us</h4>
                <p>
                    Welcome to our comprehensive legal aid app, designed to stream line dispute resolution and vehicle recovery processes for automobile financiers. Our app offers the following key features:

                </p>
                <ul>
                    <li>
                        <b>Invitation for Settlement:</b> Lawyers can invite financiers to settle disputes with debtors.
                    </li>
                    <li>
                        <b>Pre-Auction Notice:</b> Borrowers receive notice before their seized vehicle is auctioned.
</li>
                    <li>
                        <b>Post-Auction Notice:</b> Defaulters and guarantors are notified if debts remain after the auction.
</li>
                </ul>
                <p>
                    Our app is designed to simplify and accelerate the legal process, saving time and effort for financiers and lawyers alike.
                </p>

            </div>
            <div class="mt-5"></div>

            <div class="col-lg-6 listt">
                <h4 class="sub-title pe-3 mb-3">features</h4>
                <ul class="tick">

                    <li>
                        Vehicle possession transfer from borrower to lender
                    </li>
                    <li>
                        Sale of borrower's vehicle to second sales firms with lender permission
                    </li>
                    <li>
                        Disclosure of absconding borrowers and vehicle details through the app
                    </li>
                    <li>
                        Black listing of fraudulent borrowers
                    </li>
                    <li>
                        Verification of genuine No Objection Certificates issued by lenders
                    </li>
                    <li>
                        Authorization from lenders
                    </li>
                    <li>
                        Registered Seizures district wise
                    </li>
                    <li>
                        Legal support any where.
                    </li>
                    <li>
                        Registered RTA agents availability district wise
                    </li>
                    <li>
                        Seizing yard registration
                    </li>
                    <li>
                        Second sales firm registration
                    </li>
                </ul>
            </div>
            <div class="col-lg-6">
                <img src="../../../assets/img/bread.jpeg" alt="bread" class="img">
            </div>

        </div>
    </div>
</div>

