import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { BeerarserviceService } from '../app/beerarservice.service';
import { Subject } from 'rxjs';
import { Observable, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
declare var google: any;

@Injectable({
    providedIn: 'root'
})
export class GeneralserviceService {
    [x: string]: any; 
    token: any;
    HomeUrl: any;

    constructor(
        private bearer: BeerarserviceService,
        public http: HttpClient
        
    ) {
        debugger

 //this.HomeUrl = 'http://localhost:51079/';
      this.HomeUrl = 'https://financerlawapp.com/apiv1/';
     //     this.HomeUrl = 'https://financialadmin.gagriglobal.com/apiv1/';

        localStorage.setItem('URL', this.HomeUrl);
    }


    GetData(url: any): Observable<any> {
        return from(this.bearer.getBearerToken()).pipe(
            map((data: any) => {
                if (data && data.access_token) {
                    this.token = data.access_token;
                }
                return this.token;
            }),
            switchMap((accessToken: string) => {
                const headers = new HttpHeaders({
                    'Authorization': `Bearer ${accessToken}`
                });
                this.HomeUrl = localStorage.getItem('URL');
                url = this.HomeUrl + url;
                return this.http.get(url, { headers }).pipe(map(res => res));
            })
        );
    }
    Getdata(url) {
        return new Promise((resolve, reject) => {

            this.http.get('../assets/WebService.json').subscribe((data: any) => {

                this.urlname = data.Webservice;
                this.url = this.urlname + url
                this.http.get(this.url).subscribe(data => {

                    resolve(data)
                }, err => {
                    reject(err)
                })
            }, err => {
                reject(err)
            })
        });
    }
    PostData(url: any, formData: any): Observable<any> {
        debugger
        return from(this.bearer.getBearerToken()).pipe(
            map((data: any) => {

                if (data && data.access_token) {
                    this.token = data.access_token;
                }
                return this.token;
            }),
            switchMap((accessToken: string) => {

                const headers = new HttpHeaders({
                    'Authorization': `Bearer ${accessToken}` // Set the Authorization header with the Bearer token
                });
                this.HomeUrl = localStorage.getItem('URL');
                url = this.HomeUrl + url;
                return this.http.post(url, formData, { headers }).pipe(map(res => res));
            })
        );
    }

    Postdata1(url: any, fromdata: any): Observable<any> {
        debugger
        this.HomeUrl = localStorage.getItem('URL');
        url = this.HomeUrl + url;
        return this.http.post(url, fromdata).pipe(map(res => res));
    }

    PostData2(url, PostParam) {
        
        return new Promise((resolve, reject) => {

            this.http.get('../assets/WebService.json').subscribe((data: any) => {

                this.urlname = data.Webservice;
                url = data.Webservice + url
                this.http.post(url, PostParam).subscribe(data => {

                    resolve(data)
                }, err => {
                    reject(err)
                })
            }, err => {
                reject(err)
            })
        });
    }

}
