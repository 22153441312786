import { Component, OnInit, ViewChild } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { FormGroup, FormsModule } from '@angular/forms'
import { ReactiveFormsModule } from '@angular/forms';
import Swal from 'sweetalert2';
import { GeneralserviceService } from '../../generalservice.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss'
})
export class ContactUsComponent {

    EnquiryAry: any = [];
    formGroup: any;
    admin: any;
    TemplateId: any;
    MessageText: any;
    //ApiURL: any;
    contactForm: FormsModule;
    spinner: boolean = false;
    imgBase64: any = '';
    enquiery: any = [];
    HomeUrl: any;
    Fullname: any;
    EMail: any;
    Message: any;
    Name: any;
    email: any;

    constructor(private viewportScroller: ViewportScroller,
        public router: Router, public generalService: GeneralserviceService, private http: HttpClient, public fb: FormsModule, public Rm: ReactiveFormsModule) {


    }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);

    }

    ngOnInit() {

        
    }






    sendmail() {
        debugger
        this.enquiery = [];
        this.enquiery.push({
            Name: this.Fullname,
            EMail: this.email,
            Message: this.Message,
            //Phone: this.Phone

        })
        var uploadfile = new FormData;
        uploadfile.append("Email", JSON.stringify(this.enquiery));
        var url = "api/FinancierLaw/EnquiryMailTo_Azolchem";
      //  this.generalService.PostData(url, uploadfile).subscribe(data => {
        this.generalService.PostData(url, uploadfile).subscribe((data: any) => {
            if (data==='SUCCESS') {
            alert('Your query submitted successfully.');
            this.router.navigate(['/1home']);
            //this.generalService.ShowAlert("SUCCESS", 'Mail sent Successfuly', 'success');
            //Swal.fire('SUCCESS',
            //    'Your Enquiry has been submitted successfully. Our Team will contact you soon.',
            //    'success')
            this.Name = '';
            this.EMail = '';
            this.Message = '';
            }
            //setTimeout(() => {
            //    window.location.reload();
            //}, 2000);
        });

    }
}
