<div class="ptb-100">
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center ">
            <h6 class="text-white display-3 mb-4 wow fadeInDown" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInDown;">
                Absconding Form
            </h6>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInDown;">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active text-white"> Absconding Form</li>
            </ol>
        </div>
    </div>
    <div class="container" style="margin-top: 2%;margin-bottom: 15px;">
        <div class="row">
            <div class="col-12">
                <fieldset>
                    <form [formGroup]="RegisterForm">
                        <div class="row p-5">

                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BorrowerName">Borrower Name<span style="color:red">*</span> </label>
                                    <input type="text" formControlName="BorrowerName" placeholder="Enter Your Borrower Name" inputmode="numeric" maxlength="50" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BorrowerFatherName">Borrower Father Name<span style="color:red">*</span> </label>
                                    <input type="text" formControlName="BorrowerFatherName" placeholder="Enter Your Borrower Father Name" id="SecretaryAddress" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BorrowerAddress">Borrower Address<span style="color:red">*</span> </label>
                                    <input type="text" formControlName="BorrowerAddress" placeholder="Enter Your Borrower Address" id="SecretaryAddress" required>
                                </div>
                            </div>


                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BorrowerMobileNumber">Borrower Mobile Number<span style="color:red">*</span> </label>
                                    <input type="number" formControlName="BorrowerMobileNumber" placeholder="Enter Your Borrower Mobile Number" id="VehicleRegNo" required (keyup)="convertToUppercase()">
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="VehicleNumber">Vehicle Number</label>
                                    <input type="text" formControlName="VehicleNumber" placeholder="Enter Your Vehicle Number" id="ParkedAt" required>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="VehicleEngineNumber">Vehicle Engine Number<span style="color:red">*</span> </label>
                                    <input type="text" formControlName="VehicleEngineNumber" placeholder="Enter Your Vehicle Engine Number" id="RTAName" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="VehicleChassisNumber">Vehicle Chassis Number<span style="color:red">*</span> </label>
                                    <input type="text" formControlName="VehicleChassisNumber" placeholder="Enter Your Vehicle Chassis Number" id="RTAName" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="PrizeMoney">Prize Money<span style="color:red">*</span> </label>
                                    <input type="text" formControlName="PrizeMoney" placeholder="Enter Your Prize Money" id="RTAName" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label> State Name </label>
                                    <select class="form-control" formControlName="StateName" (change)="onStateSelection($event.target.value)">
                                        <option value="">Select State name</option>
                                        <option *ngFor="let statename of Statedata" [value]="statename.StateId">{{ statename.StateName }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label> District Name </label>
                                    <select class="form-control" formControlName="DistrictName" (change)="onDistrictSelection($event.target.value)">
                                        <option value="">Select District Name</option>
                                        <option *ngFor="let districtname of DistrictName" [value]="districtname.DistrictID">{{ districtname.DistrictName }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-4">

                            </div>
                            <div class="col-lg-2">
                                <div class="form-group">
                                    <button class="btn" (click)="getRegistration(RegisterForm.value)">
                                        <b>Submit</b>
                                    </button>
                                </div>
                            </div>

                            <div class="col-lg-2">
                                <div class="form-group">
                                    <button class="btn" (click)="cancel()">
                                        <b>Cancel</b>
                                    </button>
                                </div>
                            </div>

                            <!--<div class="form-group">
        <button class="btn" (click)="getRegistration(RegisterForm.value)">
            <b>Submit</b>
        </button>

        <button class="btn" (click)="cancel()">
            <b>Cancel</b>
        </button>
    </div>-->

                        </div>
                    </form>
                </fieldset>
            </div>
        </div>
    </div>
</div>
