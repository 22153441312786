import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { GeneralserviceService } from '../../generalservice.service';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-legalform',
 
  templateUrl: './legalform.component.html',
  styleUrl: './legalform.component.scss'
})
export class LegalformComponent {

    HomeUrl: string;
    arr: any[] = [];
    loginDet: any;
    otpSent: boolean = false;
    showLogin: boolean = false;
    showLogin1: boolean = true;
    registerAsDonor: boolean = false;
    RegId: any;
    LoginDetails: any;
    RegisterForm: FormGroup;
    OTPtoMobile: string;
    checkNo: number;
    Mobilenum: string;
    mobiledata: any;
    classdata: any;
    classname: any;
    deptList: any;
    deptList1: any;
    // State: any;
    StateId: any;
    DistrictName: any;
    flag: any;
    rolename: any
    Districtdata: any;
    selectedDistrictName: any;
    Statedata: any;
    State: any = [];
    selectedStateName: any;
    selectedstateid: any;
    district: any = [];
    Citydata: any = [];
    Fdata: any;
    notice7: any
    isUpdateMode: boolean = false;
    LegalNoticeID: number;
    cardNumber: any;
    Sname: any;
    constructor(
        public router: Router,
        private formBuilder: FormBuilder,
        public generalService: GeneralserviceService,
        public http: HttpClient,
        public activeroute: ActivatedRoute
    ) {
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));

        this.notice7 = localStorage.getItem("notice7");
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
        debugger
        // this.LegalNoticeID = this.cardNumber;
        this.initializeForm();

        if (!this.loginDet || !this.loginDet[0] || !this.loginDet[0].RegID) {
            this.router.navigate(['/Login']);
            return;
        }
    }


    initializeForm(): void {


        this.RegisterForm = this.formBuilder.group({
            SeizedDate: ['', Validators.required],
            DueDate: ['', Validators.required],
            BorrowerName: ['', Validators.required],
            BarrowerAddress: ['', Validators.required],
            BarrowerMobile: ['', Validators.required],
            VehicleRegNo: ['', Validators.required],
            

        });

    }
    convertToUppercase() {
        const control = this.RegisterForm.get('VehicleRegNo');
        if (control) {
            control.setValue(control.value.toUpperCase(), { emitEvent: false });
        }
    }

    ngOnInit() {

        this.Getforms();
    }
    Getforms() {
        debugger;
        const uploadfile = new FormData();
        uploadfile.append('Param', this.notice7);
        var url = "api/FinancierLaw/FA_NoticeForms_basedon_ARGNO";
        this.generalService.Postdata1(url, uploadfile).subscribe(
            data => {
                debugger;
                this.Fdata = data;
                //const auctionedDate = this.formatDate(this.Fdata[0].AuctionedDate);
                this.RegisterForm.patchValue({

                    DueDate: this.Fdata[0].DueDate,
                    SeizedDate: this.Fdata[0].SeizedDate,
                    BarrowerMobile: this.Fdata[0].BarrowerMobile,
                    BarrowerAddress: this.Fdata[0].BarrowerAddress,
                    BorrowerName: this.Fdata[0].BorrowerName,
                    VehicleRegNo: this.Fdata[0].VehicleRegNo,
                   



                });
            },
            err => {
                //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
            }
        );
    }
    formatDate(dateString: string): string {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    }

    OnSubmit() {
        throw new Error('Method not implemented.');
    }

    reg() {
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            this.router.navigate(['/Login']);
            return;
        } else {
            this.router.navigate(['/Home']).then(() => window.location.reload());
        }
    }




    getRegistration(value: any) {
        debugger;

       
        this.arr = [];

        var arr = [{
             DueDate: value.DueDate,
            SeizedDate: value.SeizedDate,
            BarrowerAddress: value.BarrowerAddress,
            BarrowerMobile: value.BarrowerMobile,
            BorrowerName: value.BorrowerName,
            VehicleRegNo: value.VehicleRegNo,
            RegID: this.loginDet[0].RegID,
            NoticeID: 15,
            LoanAmount: 0.00,
            DueAmount: 0.00,
            AuctionReceivedMoney: 0.00,
            TotalAmount: 0.00,
            MonthlyIncome: 0.00
             }];

        const uploadfile = new FormData();
        uploadfile.append('Param', JSON.stringify(arr));
        uploadfile.append('Flag', '1');

        const url = 'api/FinancierLaw/FA_NoticeFormsCRUD';
        this.generalService.Postdata1(url, uploadfile).subscribe((data: any) => {
            debugger

            if (data && data) {

                alert("Your Form submitted successfully");
                localStorage.setItem('notice5', data);
                this.router.navigate(['/legalnotice']);


            }
        },

        );
    }
    updateRegistration(value: any) {
        debugger;
        this.arr = [];

        var arr = [{
            NoticeFormID: this.Fdata[0].NoticeFormID,
            DueDate: value.DueDate,
            SeizedDate: value.SeizedDate,
            BarrowerAddress: value.BarrowerAddress,
            BarrowerMobile: value.BarrowerMobile,
            BorrowerName: value.BorrowerName,
            VehicleRegNo: value.VehicleRegNo,
            RegID: this.loginDet[0].RegID,
            NoticeID: 8,
            LoanAmount: 0.00,
            DueAmount: 0.00,
            AuctionReceivedMoney: 0.00,
            TotalAmount: 0.00,
            MonthlyIncome: 0.00

        }];

        const uploadfile = new FormData();
        uploadfile.append('Param', JSON.stringify(arr));
        uploadfile.append('Flag', '2');

        const url = 'api/FinancierLaw/FA_NoticeFormsCRUD';
        this.generalService.Postdata1(url, uploadfile).subscribe((data: any) => {
            debugger

            if (data && data) {

                alert("Your Form Updated successfully");
                localStorage.setItem('surrenderform', data);
                this.router.navigate(['/legalnotice']);
              //  localStorage.removeItem('notice7');

            }
        },

        );
    }




}
