import { Component } from '@angular/core';
import { GeneralserviceService } from '../../generalservice.service';
import { HttpClient } from '@angular/common/http';
//import { FormBuilder, FormControl, Validators, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
//import { DatePipe } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { NgModule } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-subcription',
    templateUrl: './subcription.component.html',
    styleUrl: './subcription.component.scss'
})
export class SubcriptionComponent {
    arr: any = []; transactionID: string = '';
    districtdata: any = [];
    deptList: any;
    flag: any;
    Statedata: any = [];
    profiledata: any;
    Citydata: any = []; subscriptions123:any
    deptList1: any;
    deptList2: any; bloodgroupdata: any = [];
    deptList3: any;
    subDropdownStyle: { [key: string]: string } = {};
    isMouseOverSubDropdown: boolean = false;
    showDropdownContent = false;
    selectedBloodgroup: string = '';
    selectedGender: string | null = null;
    LoginDetails1: any; LoginDetails: any;
    selectedpast: string;
    Lastdonatedate: any;
    Status: boolean = true;
    HomeUrl: any;
    loginDet: any;
    transnum: any;

    dataResult: any;
    gridview: boolean = true;
    token: any;
    subdata: any;
    PackageName: any;
    PackageDiscount: any;
    PackagePrice: any;
    PackageDuration: any;
    PackageDescription: any;
    PaymentMode: any;
    PackageDiscounts: any;
    PackageNames: any;
    PackagePrices: any;
    PackageDurations: any;
    PackageDescriptions: any;
    PaymentModes: any;
    PackageNames1: any;
    PackageDiscounts1: any;
    PackagePrices1: any;
    PackageDurations1: any;
    PackageDescriptions1: any;
    PaymentModes1: any;
    TransactionID12: any;
    transdata: any;
    AllSubscriptions: any;

    constructor(private genralservice: GeneralserviceService, public http: HttpClient, public router: Router,) {

        this.LoginDetails1 = localStorage.getItem("LoginDetails");
        
        this.LoginDetails = JSON.parse(this.LoginDetails1);
        this.TransactionID12 = localStorage.getItem("TransactionID");
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
            this.GetProduct();
        });
    }

    ngOnInit(): void {
        debugger
        this.GetProduct();
        // this.bindFormData();

        this.GetAllSubscriptions();
    }



    GetProduct() {
        debugger;
        this.arr = [];
        this.arr.push({
        });
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/FinancierLaw/FA_PackageCRUD1";
        this.genralservice.PostData2(url, UploadFile).then(data => {
            this.subdata = data;
            this.PackageName = data[0].PackageName;
            this.PackageDiscount = data[0].PackageDiscount;
            this.PackagePrice = data[0].PackagePrice;
            this.PackageDuration = data[0].PackageDuration;
            this.PackageDescription = data[0].PackageDescription;
            this.PaymentMode = data[0].PaymentMode;


            this.PackageNames = data[1].PackageName;
            this.PackageDiscounts = data[1].PackageDiscount;
            this.PackagePrices = data[1].PackagePrice;
            this.PackageDurations = data[1].PackageDuration;
            this.PackageDescriptions = data[1].PackageDescription;
            this.PaymentModes = data[1].PaymentMode;

            this.PackageNames1 = data[2].PackageName;
            this.PackageDiscounts1 = data[2].PackageDiscount;
            this.PackagePrices1= data[2].PackagePrice;
            this.PackageDurations1 = data[2].PackageDuration;
            this.PackageDescriptions1 = data[2].PackageDescription;
            this.PaymentModes1 = data[2].PaymentMode;
            // Call bindFormData with the received data
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error');
        });
    }



    /*check mobile exist or not*/
    CheckMobileNo(transactionID: string) {
        debugger
        this.transnum = transactionID;
        var UploadFile = new FormData();

        UploadFile.append("Param2", this.transnum);  // Passing transaction ID as Param2
        UploadFile.append("Param1", "3");            // Setting Param1 to "1" as per your code

        var url = "api/FinancierLaw/Get_Check_Mobile_Email";

        this.genralservice.PostData2(url, UploadFile).then(data => {
            debugger
            this.transdata = data;

            if (data === 'Transaction Exist') {
                alert("Already your Transaction exist");
            } else {
                data ='Transaction Not Exist'
            }
        }, err => {
            // Handle the error case here
            // this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error');
        });
    }

    GetAllSubscriptions() {
        var arr = [{}]
        const uploadfile = new FormData();        
        uploadfile.append('Param', JSON.stringify(arr));
        uploadfile.append('Flag', '4');
        const url = 'api/FinancierLaw/FA_subscriptionCRUD';
        this.genralservice.PostData(url, uploadfile).subscribe((data: any) => {
            this.AllSubscriptions = data;
        })
    }

    subscription(value: string) {
        debugger;
        const exists = this.AllSubscriptions.some((s: any) => s.TransactionID == value);
        // Check if the mobile number or transaction data exists
        //this.CheckMobileNo(value);
        if (exists == false) {
            let packageId;
            if (this.LoginDetails[0].RoleID === 9) {
                packageId = 6;
            } else if (this.LoginDetails[0].RoleID === 2) {
                packageId = 1;
            } else {
                packageId = 2;
            }

            const arr = [{
                RegID: this.LoginDetails[0].RegID, // Safe to access LoginDetails[0]
                TransactionID: value,
                Device: 'Webapp',
                PackageID: packageId
            }];

            // Create form data to send to API
            const uploadfile = new FormData();
            uploadfile.append('Param', JSON.stringify(arr));
            uploadfile.append('Flag', '1');

            const url = 'api/FinancierLaw/FA_subscriptionCRUD';
            localStorage.setItem("TransactionID", value);
            console.log('Sending data to API:', uploadfile);

            // Post the new subscription data to the server
            this.genralservice.PostData2(url, uploadfile).then(
                (data: any) => {
                    debugger;
                    this.subscriptions123 = data;

                    // Handle success or failure response
                    if (data === 'SUCCESS') {
                        alert("Thank you! Shortly you will get access to your account.");
                        this.router.navigate(['/1home']);
                    } else {
                        alert("Something went wrong. Please try again.");
                    }
                }
            );
        } else {
            alert("Your subscription details already exist.");
        }


        //// If the transaction does not exist, proceed with the insertion
        //if (this.transdata === 'Transaction Not Exist') {
        //    if (!value || value.trim() === "") {
        //        alert("Please enter the transaction ID.");
        //        return;
        //    }

        //    // Ensure LoginDetails is available
        //    if (!this.LoginDetails || this.LoginDetails.length === 0) {
        //        console.error('LoginDetails is null or empty.');
        //        alert("Failed to sign in: Login details are missing.");
        //        return;
        //    }

        //    // Prepare data for new transaction insertion
        //    let packageId;
        //    if (this.LoginDetails[0].RoleID === 9) {
        //        packageId = 6;
        //    } else if (this.LoginDetails[0].RoleID === 2) {
        //        packageId = 1;
        //    } else {
        //        packageId = 2;
        //    }

        //    const arr = [{
        //        RegID: this.LoginDetails[0].RegID, // Safe to access LoginDetails[0]
        //        TransactionID: value,
        //        Device: 'Webapp',
        //        PackageID: packageId
        //    }];

        //    // Create form data to send to API
        //    const uploadfile = new FormData();
        //    uploadfile.append('Param', JSON.stringify(arr));
        //    uploadfile.append('Flag', '1');

        //    const url = 'api/FinancierLaw/FA_subscriptionCRUD';
        //    localStorage.setItem("TransactionID", value);
        //    console.log('Sending data to API:', uploadfile);

        //    // Post the new subscription data to the server
        //    this.genralservice.PostData2(url, uploadfile).then(
        //        (data: any) => {
        //            debugger;
        //            this.subscriptions123 = data;

        //            // Handle success or failure response
        //            if (data === 'SUCCESS') {
        //                alert("Thank you! Shortly you will get access to your account.");
        //                this.router.navigate(['/1home']);
        //            } else {
        //                alert("Something went wrong. Please try again.");
        //            }
        //        }
        //    );
        //} else {
        //    // Handle the case where transaction data exists
        //    alert("Your subscription details already exist.");
        //}
    }





}
