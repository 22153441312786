<div id="services" class="services-area  ptb-100 content ">
    <div class="container ptb-70">
        <h1 class="text-center mb-3">Privacy Policy</h1>

        <h4> 1. Data Storage</h4>
        <p>
            We do not store any of your personal data on our website, with or without your consent.
        </p>
        <br />
        <h4> 2. No Objection and Authorization Letter</h4>
        <p>
            We store your No Objection and Authorization letter generated for a maximum of 45 days. You can issue this letter with a validity period of 30 days.
        </p>
        <br />
        <h4>3. Purchase Sale Agreement Data</h4>
        <p>
            We do not store any data related to Purchase Sale Agreements on our website.
        </p>
        <br />
        <h4> 4. Customer Information</h4>
        <p>With regards to sale agreement, we display your customer due information in your own link.</p>
        <br />
        <h4>5. Outstanding Customer Data Retention</h4>
        <p>
            We limit the presentation of outstanding customer data to a period of one year.
        </p>
        <br />
        <h4>Jurisdiction and Applicability</h4>
        <p>
            This agreement shall be governed by and construed in accordance with the laws of Telangana, with exclusive jurisdiction in Hyderabad."
        </p>
        <br />
        <h4>Updates</h4>
        <p>We reserve the right to update this policy periodically.</p>

        <h4>Acceptance</h4>
        <p>By using our services, you acknowledge acceptance of this policy.</p>
        <br />
        <h2>Contact Us</h2>
        <ul>
            <li>Visit: <a href="https://financerlawapp.com/ContactUs" target="_blank">https://financerlawapp.com/ContactUs</a></li>
            <li>Mail:  <a href="mailto:financeradvise2024@gmail.com" target="_blank"><i class="fa fa-envelope"></i> financeradvise2024&#64;gmail.com </a></li>
            <li><a href="tel:9381417022" target="_blank">Phone: <i class="fa fa-phone"></i>  +91 9381417022</a></li>
        </ul>

    </div>
</div>
